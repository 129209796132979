body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Exo2-Italic-VariableFont_wght";
  src: url("../src/assest/font/Exo_2/Exo2-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Exo2-Black";
  src: url("../src/assest/font/Exo_2/static/Exo2-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Exo2-Regular";
  src: url("../src/assest/font/Exo_2/static/Exo2-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Exo2-Bold";
  src: url("../src/assest/font/Exo_2/static/Exo2-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSans-Regular";
  src: url("../src/assest/font/Nunito_Sans/NunitoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSans-Light";
  src: url("../src/assest/font/Nunito_Sans/NunitoSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSans-Bold";
  src: url("../src/assest/font/Nunito_Sans/NunitoSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSans-SemiBold";
  src: url("../src/assest/font/Nunito_Sans/NunitoSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSans-ExtraBold";
  src: url("../src/assest/font/Nunito_Sans/NunitoSans-ExtraBold.ttf") format("truetype");
}


 @font-face {
  font-family: "Exo2-SemiBold";
  src: url("../src/assest/font/Exo_2/static/Exo2-SemiBold.ttf") format("truetype");
}
/*
@font-face {
  font-family: "Exo2-Bold";
  src: url("./assest/font/static/Exo2-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Exo2-ExtraBoldItalic";
  src: url("./assest/font/static/Exo2-ExtraBoldItalic.ttf") format("truetype");
}  */

* {
  box-sizing: border-box;
}

/* Overriding Tailwind Accordion */
.collapse {
  visibility: visible;
}