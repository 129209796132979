.myfooter{
    display: block;
}

a:hover, .content_subhead:hover {
    text-decoration: none;
    color: #2F2F2F;
}

.content_head {
    font-weight: 700 !important;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;
    color: #2F2F2F;
    margin-bottom: 16px;
}

.content_subhead {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;
    color: #2F2F2F;
    margin-bottom: 16px;

}

.content_address {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 42px; */
    display: flex;
    align-items: center;
    letter-spacing: 1.5px;
    text-transform: capitalize;
    margin-bottom: 5px;
    color: #2F2F2F;
}

.footer_last_section {
    background: #2F2F2F;
}

.footer_last_section p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #E4E9EF;
    height: 64px;
    text-align: center;
    justify-content: center;
}

.footer_four_section {
    display: flex;
    justify-content: space-around;
}

.footer_two_section {
    display: flex;
    justify-content: space-around;
    width: 50%;
}

.footer_mobile {
    display: none;
  }

  .footer_desktop {
    display: block;
  }


@media (max-width:768px) {
    .myfooter{
        display: block;
    }
}


@media (min-width: 200px) and (max-width: 550px){ 
    .footer_mobile {
      display: block;
      padding-top: 17px;
      padding-bottom: 40px;
    }

    .footer_mobile_main_section {
        display: flex;
        flex-direction: column;
        align-items: left;
        
    }

    .content_head {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        color: #2F2F2F;
        margin-bottom: 8px;
    }

    .content_subhead {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #2F2F2F;
        margin-bottom: 8px;
    }

    .footer_mobile_sub_section {
      display: flex;
      align-items: left;
      height: 200px;
    }

    .footer_desktop {
      display: none;
    }

    .content_address {
        font-size: 12px;
    }
}

@media (min-width: 551px) and (max-width: 1180px){ 
    /* .footer_mobile {
      display: block;
      padding-top: 17px;
      padding-bottom: 40px;
    }

    .footer_mobile_main_section {
        display: flex;
        flex-direction: column;
        align-items: left;
        
    } */

    .content_head {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: 0.0025em;
        color: #2F2F2F;
    }

    .content_subhead {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: 0.0025em;
        color: #2F2F2F;
    }

    /* .footer_mobile_sub_section {
      display: flex;
      align-items: left;
      height: 200px;
    }

    .footer_desktop {
      display: none;
    } */

    .content_address {
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.8px;
    }
}
   