.App {
  text-align: center;
}

a:hover {
  text-decoration: none;
  color: none;
}

ul {
  margin-top: 0px;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

.rc-anchor-alert {
  display: none !important;
}

#rc-anchor-alert, .rc-anchor-alert {
  display: none !important;
}

.accordion-item {
  background: none !important;
}


.container {
  width: 100%;
  /* max-width: 100%; */
  max-width: 1440px;
  padding: 0px;
}

.row {
  width: 100% !important;
  max-width: 100% !important;
  margin-left: 0px !important;
}

/* @property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
} */

/* .home_first_nums_20 {
  animation: counter 1s infinite alternate ease-in-out;
  counter-set: num var(--num);
  font: 800 40px system-ui;
  padding: 2rem;
}
.home_first_nums_20::after {
  content: counter(num);
}

@keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: 20;
  }
} */
/* .home_first_nums_20:hover {
} */


.cookie_popup .modal-content {
  width: 499px;
}

.cookie_privacy_link {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #2F2F2F;
  text-decoration: underline;
}

.cookie_privacy_link:hover {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #2F2F2F;
  text-decoration: underline;
}

.cookie_modal_content {
  padding: 15px;
}


.cookie_head {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #2F2F2F;
}

.cookie_content {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2F2F2F;
  margin-top: 16px;
  margin-bottom: 24px;
}

.cookie_button_container {
 width: 100%;
 display: flex;
}

.cookie_accept {
  background: #0087FF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 54px;
  letter-spacing: 1.5px;
  color: #FFFFFF;
  width: 163px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
}

.cookie_reject {
  width: 163px;
  height: 33px;
  border: 1px solid #000000;
  filter: drop-shadow(0px 4px 4px rgba(0, 135, 255, 0.25));
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 54px;
  letter-spacing: 1.5px;
  color: #2F2F2F;
  text-shadow: 6px 9px 22px rgba(0, 135, 255, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
}


.home_desktop_landing_page {
  margin-top: 100px;
  display: block;
}

.home_mobile_landing_page {
  display: none;
  margin-top: 10px;
}

.home_first_content_numberSection {
  display: flex;
  flex-direction: row;
}

.home_first_content_number1 {
  width: 140px;
  height: 74px;
}

.home_first_content_number1_de {
  width: 190px;
  height: 74px;
  /* style={{width:'190px', height:'74px'}} */
}

.home_first_content_number2 {
  width: 189px;
  height: 74px;
  margin: 0px 76px 0px 76px;
}

.home_first_content_number3 {
  width: 130px;
  height: 74px;
}

.home_first_content_number3_de {
  width: 145px;
  height: 74px;
}

.home_first_content_contact {
  margin-top: 5px;
}

.contact_us {
  /* width: 100%; */
  height: 54px;
  background: #0087FF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  padding: 0px 24px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 54px;

  text-align: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
}

.contact_us:hover { 
  height: 54px;
  padding: 0px 24px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #2F2F2F;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #2F2F2F;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact_us2 {
  /* width: 127px; */
  height: 54px;
  padding: 0px 24px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #2F2F2F;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #2F2F2F;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact_us2:hover, .whats_in_buttons:hover {
  /* width: 100%; */
  height: 54px;
  background: #0087FF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  padding: 0px 24px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 54px;

  text-align: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
}

.whatsIn_Button_containers {
  justify-content: center;
}


.whats_in_buttons {
  box-sizing: border-box;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  gap: 8px; */

  /* width: 394px; */
  height: 54px;
  padding: 0px 24px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #2F2F2F;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;

  height: 54px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #2F2F2F;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.project_landing_desktop_img {
  display: block;
}

.project_landing_mobile_img {
  display: none;
}

.Home_section5_desktop_button1 {
  display: block;
}

.Home_section5_mobile_button1 {
  display: none;
}

.Home_section6_desktop_button1 {
  display: block;
}

.Home_section6_mobile_button1 {
  display: none;
}

.Home_section7_desktop_button1 {
  display: block;
}

.Home_section7_mobile_button1 {
  display: none;
}

#inner {
  width: 500px;
  overflow: hidden !important
}

#platform {
  width: 227%;
  height: 90px;
  position: relative;
  /* top: 150px; */
  right: 0;
  transform: translate(40%);
  margin-left: auto;
  animation: move 35s linear infinite;
  display: flex;
  align-items: center;
}

/* #platform a {
} */

.mobile_slider {
  display: none;
}

.desktop_slider {
 display: block;
}

.slider_main_container {
  background-color: #FAFBFC;
  height: 251px;
  margin-top: 80px;
}

.service_main_container {
  margin-top: 120px;
}

.slick-slide {
  width: 20% ;
  height: 135px;
  margin-top: 45px;
}
.slick-slide .phoenix_a{
  width: 128px !important;
  display: inline-block !important;
  margin-left: 25px !important;
}

.slick-slide .hosh_a{
  width: 128px !important;
  display: inline-block !important;
  margin-left: 25px !important;
}

.slick-slide .bauer_a{
  width: 128px !important;
  display: inline-block !important;
  margin-left: 20px !important;
}

.slick-slide .bim_a{
  width: 128px !important;
  display: inline-block !important;
  margin-left: 15px !important;
}

.slick-slide .lubbing_a{
  width: 128px !important;
  display: inline-block !important;
  margin-left: 10px !important;
}

.slick-slide .wago_a{
  width: 128px !important;
  display: inline-block !important;
  margin-left: 10px !important;
}

.slick-slide .selve_a{
  width: 128px !important;
  display: inline-block !important;
  margin-left: 10px !important;
}

.slick-slide .vatten_a{
  width: 128px !important;
  display: inline-block !important;
  margin-left: -80px !important;
}

.slick-slide .volks_a{
  margin-left: -50px !important;
}

.slick-slide .vono_a{
  margin-left: -60px !important;
}

.slick-slide .bvg_a{
  margin-left: -20px !important;
}

.slick-slide .fugitsu_a{
  margin-left: -65px !important;
}

.slick-slide .gfr_a{
  margin-left: -50px !important;
}

.slick-slide .hermos_a{
  margin-left: -110px !important;
}

.slick-slide .lae_a{
  margin-left: -110px !important;
}


.slick-slide .trio_a{
  margin-left: -120px !important;
}


.slick-slider {
  height: 100%;
  width: 95% ;
  margin-left: 32px;
}

.slick-next:before, .slick-prev:before {
  color: #2f2f2f;
}

.build_twins {
  width: 84.85px !important;
  height: 38px;
  cursor: pointer;
  /* margin-left: 178px; */
  margin-top: 22px;
}

.build_twins:hover {
  -ms-transform: scale(50); 
  -webkit-transform: scale(50); 
  transform: scale(2.0, 2.0) !important;
  /* height: 109px; */
}

.build_twins_horn_a{ 
  display: flex;
  align-items: center;
  /* margin-right: 75px; */
  width: 146px !important;
  margin-left: -32px !important;
}


.build_twins_horn{
  width: 140px !important;
  height: 16px;
  cursor: pointer;
  /* margin-left: 178px; */
  margin-top: 32px;
}


.build_twins_horn:hover{
  -ms-transform: scale(10); 
  -webkit-transform: scale(10); 
  transform: scale(1.7, 1.7) !important;
  /* height: 130px; */
}

.bvg {
  width: 75.85px !important;
  height: 40px;
}

.volks {
  width: 155px !important;
}

.dabbel {
  width: 110.85px !important;
  height: 70px;
}

.gfr {
  height: 48px;
  width: 45px !important;
}

.vatten {
  width: 150px !important;
  /* height: 110px !important; */
}

.vatten:hover{
  -ms-transform: scale(10); 
  -webkit-transform: scale(10); 
  transform: scale(1.8, 1.8) !important;
  /* height: 130px; */
}




@keyframes move {
  to {
    right: 255%;
    transform: translate(0);
  }
}
/* .slick-slide {
  width: 150px !important;
} */

.slick-slide div{
  /* width: 85px !important; */
  margin: 0px 32px
}

.sub_move_towards_body{
  width: 100%;
  height: 180px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
}

.sub_move_towards_body_de{
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
}


.aboutus_first_content_desktop {
  display: block;
}

.aboutus_first_content_mobile {
  display: none;
}

.aboutus_desc_container {
  display: flex;
  margin-top: 120px;
  margin-bottom: 10px;
}

.about_project_desc {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 50%;
  margin-left: 15px;
}

.about_project_img {
  width: 47%;
  /* min-height: 700px; */
  max-height: 740px;
  border-radius: 40px;
}

.aboutus_storyhead {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.0025em;
  color: #2F2F2F;
  margin-bottom: 32px;
}

.aboutus_storycontent {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
}

.coreValues_container {
  display: flex;
  margin-top: 120px;
  margin-bottom: 10px;
  padding: 15px;
}

.coreValues_mainContent {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  /* margin-left: 15px; */
}

.coreValues_mobile {
  display: none;
}

.coreValues_desktop {
  display: block;
}

.cultivating_desc_mobile  {
  display: none;
}

.cultivating_desc_desktop  {
  display: block;
}


.coreValues_subContent_container {
  display: flex;
  justify-content: space-between;
}

.coreValues_subContent {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coreValues_subContent_img {
  width: 60px;
}



.reasonToCelebrate_container {
  display: flex;
  margin-top: 120px;
  margin-bottom: 10px;
  padding: 15px;
}

.reasonToCelebrate_mainContent {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  /* margin-left: 15px; */
}

.reasonToCelebrate_subContent_container {
  display: flex;
  justify-content: space-between;
  padding: 0px 155px;
}

.reasonToCelebrate_subContent {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.about_line {
  width: 16%;
  margin-left: 55px;
  margin-top: 13px;
  position: absolute;
  border-top: 3px dashed #0087FF;
  z-index: -20;
}

.reasonToCelebrate_topContent {
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #2F2F2F;
}

.reasonToCelebrate_storycontent {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #2F2F2F;
  width: 135px;
}

.reasonToCelebrate_subContent_img {
  width: 35px;
  margin-bottom: 33px;
}

.reasonToCelebrate_subContent:hover .reasonToCelebrate_subContent_img{
  -ms-transform: scale(50); 
  -webkit-transform: scale(50); 
  transform: scale(2, 2) !important;
  /* height: 109px; */
}

.reasonToCelebrate_year_text {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #2F2F2F;
}

/* .reasonToCelebrate_subContent:hover .reasonToCelebrate_storycontent{
  -ms-transform: scale(50); 
  -webkit-transform: scale(50); 
  transform: scale(1.1, 1.1) !important;
} */

.reasonToCelebrate_line {
  width: 198px;
  margin-left: 75px;
  margin-top: 7px;
  position: absolute;
  /* top: 10%; */
  border-top: 2px dashed #0087FF;
}

.cultivating_desc_container {
  display: flex;
  margin-top: 120px;
  margin-bottom: 120px;
}

.cultivating_project_desc {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 51%;
  margin-left: 15px;
}

.cultivating_project_img {
  width: 47%;
  /* min-height: 700px; */
  max-height: 740px;
}

.cultivating_storyhead {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.0025em;
  color: #2F2F2F;
  margin-bottom: 32px;
}

.cultivating_storycontent {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  margin-bottom: 50px !important;
}

.career_position_container {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
}

.career_position_body{
  width: 100%;
  /* height: 180px; */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.career_position_body_de{
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  align-items: left;
  /* height: 100%; */
  /* background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25); */
}

.career_top_img {
  /* height: 310px;
  width: 280px; */
  margin-left: 45%;
}

.position_first_content_block {
  width: 100%;
  margin-top: 145px;
  max-width: 100%;
  text-align: left;
  padding: 0px 25px;
}

.position_blueHead {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #0087FF;
}

.position_mainHead {
  margin-top: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 70px;
  letter-spacing: 0.002em;
  color: #2F2F2F;
}

.position_subblueHead {
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #4EA0FD;
}

.positionFirst_content_subhead {
  margin-top: 56px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: #2F2F2F;
}

.positionFirst_content_subcontent {
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
}

.positionFirst_content_middle_subcontent {
  display: flex;
  flex-direction: column;
  margin: 0px 80px;
}


.position_second_content_block {
  margin-top: 120px;
  padding: 0px 30px;
}

.position_about_leaftech_head {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #2F2F2F;
}

.position_about_leaftech_content {
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
}

.position_third_content_subcontent {
  margin-top: 120px;
  padding: 0px 30px;
}

.responsibility_and_expect {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.resEexpect_content_block {
  display: flex;
  align-items: center;
  padding: 0px;
  width: 50%;
}

.p1_sideline_ul1 {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: 2px solid #0087FF;
    /* width: 10px; */
    height: 95%;
    background-color: black;
    margin-top: 2%;

  
  /* height: 410px; */
  /* margin-top: 15px; */
  /* width: 4px; */
}

.p1_sideline_ul2 {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: 2px solid #0087FF;
    /* width: 10px; */
    height: 96%;
    background-color: black;
    margin-top: 2%;

  
  /* height: 410px; */
  /* margin-top: 15px; */
  /* width: 7px; */
}

.p1_sideline_ul3 {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: 2px solid #0087FF;
    /* width: 10px; */
    height: 96%;
    background-color: black;
    margin-top: 2%;

  
  /* height: 340px; */
  /* margin-top: 15px; */
  /* width: 4px; */
}

.p1_sideline_ul4 {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: 2px solid #0087FF;
    /* width: 10px; */
    height: 96%;
    background-color: black;
    margin-top: 2%;

  
}

.p2_sideline_ul1 {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: 2px solid #0087FF;
    /* width: 10px; */
    height: 96%;
    background-color: black;
    margin-top: 2%;

  
  /* height: 325px; */
  /* margin-top: 15px; */
  /* width: 4px; */
}

.p2_sideline_ul2 {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: 2px solid #0087FF;
    /* width: 10px; */
    height: 96%;
    background-color: black;
    margin-top: 2%;

  
  /* height: 288px; */
  /* margin-top: 15px; */
  /* width: 5px; */
}

.p2_sideline_ul3 {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: 2px solid #0087FF;
    /* width: 10px; */
    height: 96%;
    background-color: black;
    margin-top: 2%;

  display: block;
  /* height: 350px; */
  /* margin-top: 15px; */
  /* width: 4px; */
}

.p2_sideline_ul4 {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: 2px solid #0087FF;
    /* width: 10px; */
    height: 96%;
    background-color: black;
    margin-top: 2%;

  
  /* height: 350px; */
  /* margin-top: 15px; */
  /* width: 5px; */
}

.ul_line {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 129px;
  border-left: 5px solid blue;
  width: 10px;
  height:40px;
  background-color: black;
}

.sideline_ul {
  height: 97%;
  margin-top: 12px;
}

.position_resExpect_li {
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2F2F2F;
  padding: 0px 16px;
}

.position_fourth_content_block {
  margin-top: 120px;
  padding: 0px 30px;
}

.position_fifth_content_block {
  margin-top: 120px;
  padding: 0px 30px;
}

.weOffer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}


.weOffer_content_block {
  display: flex;
  padding: 0px;
}


.position_weOffer_li {
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2F2F2F;
  padding: 0px 16px;
}

.weOffer_sideline {
  /* height: 175px;
  width: 2px;
  margin-top: 15px; */
  border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: 2px solid #0087FF;
    /* width: 10px; */
    height: 96%;
    background-color: black;
    margin-top: 1%;
}

.position_sixth_content_block {
  margin-top: 120px;
  margin-bottom: 120px;
  padding: 0px 30px;
  display: flex;
}

.moreInfo_container {
  display: flex;
  flex-direction: column;
}

.position_moreInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #2F2F2F;
  margin-bottom: 64px;
}

.position_moreInfo_img_container {
  width: 25%;
}

.position_moreInfo_img {
 width: 158px;
}

.project_home {
  margin-left: 15px !important;
}

.project_desktop {
  display: block;
}

.project_mobile {
  display: none;
}

.project_tab {
  display: none;
}


.sub_move_towards_body:hover, .sub_move_towards_body:active {
  background: #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
}

.sub_move_towards_body_de:hover, .sub_move_towards_body_de:active {
  background: #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
}

.career_position_container:hover, .career_position_container:active {
  background: #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  /* height: 158px; */
  padding: 24px;
  text-align: left;
}
/* 
.career_position_container_de:hover {
  background: #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  text-align: left;
  padding: 24px;
} */


.sub_move_towards_head {
  font-size: 24px;
  font-weight: 600px;
  line-height: 29px;
  /* width: 400px; */
  height: 29px;
  /* fontSize: '20px', lineHeight:'32px', fontWeight: '700' */
}

.career_position_head {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #2F2F2F;
  /* margin-left: 15px; */
}

.career_position_content {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0087FF;
  margin-top: 8px;
  /* margin-left: 15px; */
}

.career_position_content_de {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0087FF;
  margin-top: 8px;
  margin-left: 15px;
}

.arrowicon {
  width: 44px;
}


.sub_move_towards_content {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  /* padding: 0px 34px; */
  margin-top: 12px;
  width: 370px;
  height: 96px;
  margin-left: 15px;
  
  /* padding: 0px; */
  /* fontSize: '20px', lineHeight:'32px', fontWeight: '700' */
}

.sub_move_towards_content_de{
  /* min-width: 300px; */
  height: 100%;
  max-width: 400px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  /* padding: 0px 34px; */
  margin-top: 12px;
 }

.for_bld_owners_img1 {
  width: 100%;
  margin-top: 64px;
  /* height: 461px; */
  margin-left: 5px
}

.for_bld_owners_img2 {
  width: 100%;
  margin-top: 0px;
  margin-left: 10px;
  /* height: 432px; */
}

.for_bld_owners_img3 {
  width: 96%;
  margin-top: 0px;
  margin-left: 5px;
  /* height: 481px; */
}

.for_bld_owners_img4 {
  width: 100%;
  margin-top: 0px;
  margin-left: 5px;
  /* height: 579px !important; */
}

.for_bld_owners_img5 {
  width: 100%;
  margin-top: 25px;
  /* height: 525px; */
  margin-left: 5px;
}

.for_bld_owners_content_box {
  /* width: 665px !important; */
  color:#2f2f2f;
  height: 100%;
  margin-left: -17px;
  width: 60%;
  margin-left: 20px;
}

.for_bld_owners_content_box2 {
  /* width: 663px !important; */
  color:#2f2f2f;
  height: 100%;
  margin-left: 5px;
  width: 60%;
  margin-right: 20px;
}

.for_bld_owners_content_box3 {
  /* width: 610px !important; */
  color:#2f2f2f;
  height: 100%;
  margin-left: 32px;
  width: 60%;
  margin-left: 20px;
}

.for_bld_owners_content_box4 {
  /* width: 665px !important; */
  color:#2f2f2f;
  height: 100%;
  margin-left: -20px;
  width: 60%;
  margin-left: 20px;
}

.for_bld_owners_content_box5 {
  /* width: 665px !important; */
  color:#2f2f2f;
  height: 100%;
  margin-left: 5px;
  width: 60%;
  margin-right: 20px;
}

.for_bld_owners_content_box6 {
  /* width: 665px !important; */
  color:#2f2f2f;
  height: 100%;
  margin-left: -8px;
  width: 60%;
  margin-left: 20px;
}

.for_bld_owners_content_caption {
  /* width: 249px; */
  /* height: 32px; */
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-style: normal;
  /* Blues/Blue */

  color: #0087FF;
}

.for_bld_owners_content_head {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  /* height: 100%; */
  width: 100%;
  margin-bottom: 24px;
  /* or 138% */


  /* Blacks/Black 100% */

  color: #2F2F2F;
}

.for_bld_owners_content {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 42px;
  /* or 160% */


  /* Blacks/Black 100% */
  /* height: 128px; */
  color: #2F2F2F;

}

.access_your_head_desktop {
  display: block;
}

.access_your_head_tab  {
  display: none;
}

.access_your_head {
  /* height: 86px; */
  margin-top: 64px;
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  /* letter-spacing: 0.0025em; */
  color: #2F2F2F;
}


.access_your_content {
  height: 81px;
  width: 450px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
  margin-top: 24px;
}

.access_your_img_container {
 width: 580px;
 height: 400px;
 margin-top: 63px;
}

.partner_company {
  width: 264px;
  height: 128px;
}

.partner_company img {
  margin-top: 30px;
}

.weSupport_mainSection {
  margin: 184px 175px 200px 175px;
} 

.weSupport {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #2F2F2F;
}

.weSupport_rowContainer {
  display: flex;
  flex-direction: column;
}

.weSupport_row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.weSupport_row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px !important;
}





.abc:hover {
  -ms-transform: scale(1.2); 
  -webkit-transform: scale(1.2); 
  transform: scale(1.2); 
}


.single_service_block {
  text-align: left;
  margin-top: 72px;
  /* margin-left: 15px; */
}

.project_nums {
  margin-top: -65px;
  /* padding-bottom: 45px; */
  /* width: 585px; */
  font-size: 14px;
  margin-left: 0px;
}

.project_nums_center {
  text-align: left;
  margin: 0px 48px;
  flex-direction: column;
}

.project_nums2 {
  margin-top: -25px;
  padding-bottom: 25px;
  width: 645px;
}

.get_in_touch_desktop .get_in_touch {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #2F2F2F;
}


.modelling_box {
  width: 434px;
  height: 139px;
}

/* .home_first_image{
  width: 750px; 
  height: 688px;
} */

.home_first_image img{
  /* margin-left: 365px;  */
    position: absolute;
    top: 12%;
    width: 633px;
    color: #2F2F2F;
    left: 51%;
}

.project_outline {
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
}

.project_outline:hover {
  box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
}

.get_in_touch_cotainer {
  background: none;
  padding: 28px 0px;
  margin-top: 120px;
  text-align: center;
}

@media only screen and (min-width: 114px) { 
 .project_outline {
  display: flex;
  /* flex-direction: column; */
  margin-left: -10px;
  justify-content: center;
}
}

@media only screen and (min-width: 780px) { 
 .project_outline {
  flex-direction: row;
  display: flex;
  margin-left: 10px;
  justify-content: center;
}
}

.service_3d_head {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.0025em;
  color: #2F2F2F;
}

.project_first_content_block {
  width: 102%;
  margin-top: 75px;
  max-width: 100%;
  text-align: left;
}

.aboutus_first_content_block {
  width: 100%;
  margin-top: 145px;
  max-width: 100%;
  text-align: left;
}

.career_first_content_block {
  width: 100%;
  margin-top: 145px;
  max-width: 100%;
  text-align: left;
  margin-bottom: 130px;
}

.jobdesc_first_content_block {
  width: 102%;
  margin-top: 75px;
  max-width: 100%;
  text-align: left;
}


.projectImage{
  width: 49%;
  /* height: 100%; */
  border-radius: 20px !important;
  margin-top: 5px !important;
}

.project_icons {
  width: 24px;
  height: 24px;
}

.project_icon_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2F2F2F;
}

.project_content_body {
  width: 95%;
  height: 512px;
  margin-top: 70px;
  margin-left: 32px;
  text-align: left;
}

.project_wago_content_body {
  width: 95%;
  height: 512px;
  margin-top: 40px;
  margin-left: 32px;
}

.project_blue_head {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #0087FF;
}

.project_head {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #2F2F2F;
}

.project_content {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
}

.project_content_numbers {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.0025em;
  text-transform: lowercase;
  color: #2F2F2F;
}

.project_no_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #2F2F2F;

}



.get_in_touch_mobile {
  display: none;
}

.get_in_touch_desktop {
  display: block;
}

.single_project_container {
  background: linear-gradient(89.82deg, #FFFFFF 0.23%, rgba(255, 255, 255, 0.555775) 45.36%, rgba(228, 233, 239, 0.5) 83.29%, #E4E9EF 97.33%);
}

.single_project_blue_head {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #0087FF;
}

.single_project_main_head {
  margin-top: 16px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 70px;
  letter-spacing: 0.002em;
  color: #2F2F2F;
  /* height: 70px; */
}

.single_project_main_head_de {
  margin-top: 16px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 70px;
  letter-spacing: 0.002em;
  color: #2F2F2F;
  /* height: 70px; */
  width: 93%;
}

.single_project_location_head {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #4EA0FD;
}

.single_project_content {
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  width: 1005px !important;
  /* height: 96px; */
}

.mann_project_content {
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  width: 983px !important;
  /* height: 96px; */
}


.single_project_content_de {
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  width: 845px !important;
  height: 96px;
}

.single_service_content_de {
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  width: 970px !important;
  height: 96px;
}

.single_service_content {
  margin-bottom: 40px;
  margin-top: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  width: 930px !important;
  height: 96px;
}

.single_service_analytics_content {
  margin-bottom: 40px;
  margin-top: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  width: 930px !important;
  height: 96px;
  /* margin-top: 105px; */
}

.service_benefits_section {
  margin-top: 120px;
  margin-bottom: 120px;
}

.control_service_content_de {
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  width: 1007px !important;
  height: 96px;
}

.control_service_content {
  margin-bottom: 40px;
  margin-top: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  width: 997px !important;
  height: 96px;
} 

.service_benfits_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.service_benfits_content {
  min-width: 57%;
  max-width: 850px;
  height: 100%;
  margin-left: 20px;
  text-align: left;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

/* .service_benfits_container img {
  width: 57%;
} */

.single_project_desc_image {
  width: 45% !important;
  height: 670px;
  border-radius: 20px;
  margin-left: 15px;
}

.single_project_desc {
  width: 50% !important;
  height: 580px;
  margin-left: 20px;
  margin-top: 45px;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.single_project_desc_head {
  margin-top: 35px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 0.005em;
  color: #373F41;
  height: 60px;
  /* margin-left: 15px; */
  width: 97% !important;

}

.single_project_desc_p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  /* margin-left: 15px; */
  /* height: 64px; */
  width: 99% !important;
  color: #2F2F2F;
  /* margin-bottom: 40px; */
}








.mann_project_desc_image {
  width: 45% !important;
  height: 670px;
  border-radius: 20px;
  margin-left: 15px;
}

.mann_project_desc {
  width: 50% !important;
  height: 580px;
  margin-left: 20px;
  margin-top: 35px;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.mann_project_desc_head {
  margin-top: 35px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 0.005em;
  color: #373F41;
  height: 60px;
}

.mann_project_desc_head_de {
  margin-top: 10px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 0.005em;
  color: #373F41;
  height: 60px;
}

.mann_project_desc_p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  /* height: 64px; */
  /* width: 665px !important; */
  color: #2F2F2F;
  /* margin-bottom: 40px; */
}


.results_benefits_subhead_desk {
  display: block;
}

.results_benefits_subhead_mob {
  display: none;
}


.mann_project_desc_subhead {
  display: none;
}

.legal_content_container {
  padding: 0px 25px;
  margin-top: 40px;
}

.legal_subhead {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #2F2F2F;
  margin-bottom: 24px;
  font-family: "Exo2-Regular"
}

.legal_subcontent {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  font-family: 'NunitoSans-Regular';
}

@media only screen and (max-width: 556px) { 
  .modelling_box {
    width: 434px;
    height: 139px;
  }
}


 
  @media only screen and (max-width: 1300px) {
    /* .home_first_image{
      margin-left: 105px;
    } */

    /* .project_nums {
      width: 100%;  

    }

    .project_nums2 {
      width: 100%;  
    } */
  }

.home_first_row {
  /* display: flex; */
margin-top: 120px;
}

.home_first_content {
  /* width: 896px; */
  margin-left: 10px;
  /* height: 593px; */
  /* margin-bottom: 62px; */
}

.home_first_content_head {
  font-weight: 700;
  font-size: 58px;
  line-height: 70px;
  letter-spacing: 0.002em;
  color: #2F2F2F;
  /* height: 140px; */
  width: 935px;
}

.home_first_content_head_de {
  font-weight: 700;
  font-size: 58px;
  line-height: 70px;
  letter-spacing: 0.002em;
  color: #2F2F2F;
  /* height: 140px; */
  width: 750px;
}

.home_first_subcontent {
  margin-top: 32px;
  margin-bottom: 35px;
  /* height: 96px; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
}

.home_first_subcontent1 {
  margin-top: 32px;
  margin-bottom: 80px;
  /* height: 96px; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
}
.home_first_subhead {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  margin-bottom: 24px;
  color: #2F2F2F;
}

.home_first_nums {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #2F2F2F;
}

.home_first_nums_p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #2F2F2F;
 }

 .weCreatedHead {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: #2F2F2F;
  margin-top: 40px;
 }

.clientImageBox {
  width: 225px;
  height: 128px;
}

.clientImageBox img {
  width: 200px;
  padding: 10px;
  /* height: 128px; */
}

.project_name_head {
cursor: pointer;
}

.MuiStep-root{
  display: flex;
}

.steeper {
  width: 98% !important;
}

.MuiBox-root  {
  width: 100% !important;
  max-width: 100% !important;

}

.MuiStepLabel-root {
  width: 160%;
  height: 50px;
}

.MuiStepContent-root {
  border-left: none !important;
}

.MuiStepContent-root {
  width: 150%;
}


.stepper_desc_head {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  color: #2F2F2F;
}

.stepper_desc_p_de {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  margin-bottom: 40px;
  /* width: 730px; */
}

.control_stepper_desc_p_de {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  margin-bottom: 40px;
  /* width: 675px; */
}

.stepper_desc_p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  margin-bottom: 40px;
  /* width: 710px; */
}

.stepper_desc_img{
  width: 100%;
  /* height: 435px; */
}

.stepperService_desc_img{
  width: 100%;
}



.results_benefits_head {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 0.005em;
  color: #2F2F2F;
  margin-bottom: 21px;
}


  
.resultContent_container {
  margin-top: 114px;
  margin-bottom: 72px;
  display: flex;
  flex-direction: row;
}

.results_benefits_content {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  margin-bottom: 40px;
  /* width: 670px; */
}

.results_benefits_subhead {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #2F2F2F;
  margin-bottom: 16px;
  /* width: 655px; */
}

.discover_how {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #2F2F2F;
  margin-bottom: 55px;
}

.career_wantToLearn_row {
  margin: 120px 0px;
}

.career_positions {
  margin: 120px 0px;
  text-align: left;
  width: 98% !important;
}

.career_positions_mainhead {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.0025em;
  color: #2F2F2F;
  margin-left: 10px;
}

.wantToLearn {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #2F2F2F;
  margin-bottom: 55px;
}

.solution_container {
  text-align: left;
  margin-top: 120px;
}

.mann_solution_container {
  text-align: left;
  margin-top: 120px;
}

.solution_desc_head {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 0.005em;
  color: #2F2F2F;
  margin-left: 15px;
  width: 97%;
}

.solution_project_desc_p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
  margin-left: 20px;
  margin-top: 24px;
  margin-bottom: 44px;
  width: 97%;
}


.phoenix_stepper{ 
  padding: 0px 80px;
}

.allsteper {
  display: flex;
  width: 100% !important;
}

.allsteper2 {
  display: flex;
  padding: 0 65px;
  margin-top: 30px;
  width: 98% !important;
}

.allsteper3 {
  display: flex;
  padding: 0 105px;
  margin-top: 30px;
  width: 98% !important;
}

.stepLabel {
  width: 50%;
  display: flex;
}

.tab-content{
  width: 50%;
}

.tab-content .tab-pane{
  background-color: white;
}

.nav-pills .nav-link {
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
}

.nav-pills .nav-link.active {
  background: #FAFBFC;
  padding: 0px;
  font-style: normal;
  z-index: -2;
  padding: 16px;
  border-radius: 20px;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
}

.nav-pills .nav-link.active .circle {
  width: 23px;
  height: 23px;
  margin-left: -2px;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
}

.service .nav-pills .nav-link {
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
  margin-left: -30px;

}

.service .nav-pills .nav-link.active {
  background: #FAFBFC;
  padding: 0px;
  font-style: normal;
  z-index: -2;
  padding: 14px;
  border-radius: 20px;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
  border-radius: 20px;
  margin-left: -28px;
}

.service .nav-pills .nav-link.active .circle {
  width: 23px;
  height: 23px;
  margin-left: -2px;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
}

.subcontent {
  width: 100%;
  /* margin-left: 25px; */
}

/* .subcontent img {
  width: 80%;
  margin-top: 20px;
} */
/* 
.subcontent p {
  width: 80%;
} */



.subcontent2 {
  width: 100%;
  /* margin-left: 25px; */
}
/* 
.subcontent2 img {
  width: 90%;
  margin-top: 20px;
}

.subcontent2 p {
  width: 90%;
} */











.step {
  
  padding: 10px;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  background-color: cream;
}

.v-stepper {
  position: relative;
  /* top: 115px; */
/*   visibility: visible; */
}


/* regular step */
.step .circle {
  background-color: white;
  border: 3px solid gray;
  border-radius: 100%;
  margin-right: 16px;
  /* margin-top: 74px; */
  width: 20px;    /* +6 for border */
  height: 20px;
  display: inline-block;
}

.service_row .step .circle {
  background-color: white;
  border: 3px solid gray;
  border-radius: 100%;
  margin-right: 16px;
  /* margin-top: 34px; */
  width: 20px;    /* +6 for border */
  height: 20px;
  display: inline-block;
}

.service_row .step .line {
  height: 88px;
  margin-left: 9px;
  margin-top: -20px;
  position: absolute;
  border-left: 3px solid gray;
}

.control .line {
  height: 120px;
  margin-left: 2px;
  margin-top: -20px;
  position: absolute;
  border-left: 3px solid gray;
}

.v-stepper:first-child .circle {
  margin-top: 10px;
  margin-left: 5px;
}

.v-stepper:first-child .line {
  margin-top: 20px;
  margin-left: 14px;
}

.step .line {
  /* top: 75px;
  left: 9px; */
/*   height: 120px; */
  height: 105px;
  margin-left: 9px;
  margin-top: -20px;
  position: absolute;
  border-left: 3px solid gray;
}

.step.completed .circle {
  visibility: visible;
  background-color: #0087FF;
  border-color: #0087FF;
}

.step.completed .line {
  border-left:1px dashed #0087FF;
}

.step.active .circle {
visibility: visible;
  border-color: #0087FF;
}

.step.empty .circle {
    visibility: hidden;
}

.step.empty .line {
/*     visibility: hidden; */
/*   height: 150%; */
  top: 0;
  height: 150%;
}


.step:last-child .line {
  border-left:1px dashed #0087FF;
  z-index: -1; /* behind the circle to completely hide */
}



.PhoenixProjectImage {
  width: 47% !important;
  
}


.project_landing_shortdesc {
  width: 70%;
}

.project_landing_single_section {
  text-align: left;
  margin-top: 120px;
}

.project_landing_blueHead {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #0087FF;
}

.project_landing_mainHead {
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 70px;
  letter-spacing: 0.002em;
  color: #2F2F2F;
}

.project_landing_content {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  margin-bottom: 64px;
  width: 775px
}


.project_landing_content_de {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  margin-bottom: 64px;
  width: 780px
}

.privacy_landing_toprow {
  margin-top: 100px;
  margin-left: 20px;
  background: linear-gradient(89.82deg, #FFFFFF 0.23%, rgba(255, 255, 255, 0.555775) 45.36%, rgba(228, 233, 239, 0.5) 83.29%, #E4E9EF 97.33%);
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.project_landing_toprow {
  background: linear-gradient(89.82deg, #FFFFFF 0.23%, rgba(255, 255, 255, 0.555775) 45.36%, rgba(228, 233, 239, 0.5) 83.29%, #E4E9EF 97.33%);
}

.aboutus_landing_toprow {
  background: linear-gradient(89.82deg, #FFFFFF 0.23%, rgba(255, 255, 255, 0.555775) 45.36%, rgba(228, 233, 239, 0.5) 83.29%, #E4E9EF 97.33%);
}

.career_landing_toprow {
  background: linear-gradient(89.82deg, #FFFFFF 0.23%, rgba(255, 255, 255, 0.555775) 45.36%, rgba(228, 233, 239, 0.5) 83.29%, #E4E9EF 97.33%);
}

.jobdesc_landing_toprow {
  background: linear-gradient(89.82deg, #FFFFFF 0.23%, rgba(255, 255, 255, 0.555775) 45.36%, rgba(228, 233, 239, 0.5) 83.29%, #E4E9EF 97.33%);
}

.single_project_row_container {
  margin-top: 75px;
  height: 533px;
  /* background: linear-gradient(89.82deg, #FFFFFF 0.23%, rgba(255, 255, 255, 0.555775) 45.36%, rgba(228, 233, 239, 0.5) 83.29%, #E4E9EF 97.33%); */
}

.single_analytics_row_container {
  margin-top: 75px;
  height: 533px;
  /* background: linear-gradient(89.82deg, #FFFFFF 0.23%, rgba(255, 255, 255, 0.555775) 45.36%, rgba(228, 233, 239, 0.5) 83.29%, #E4E9EF 97.33%); */
}

.single_project_number {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.0025em;
  color: #2F2F2F;
}

.single_project_number_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #2F2F2F;
}

.get_in_touch_cotainer button {
  margin-bottom: 120px !important;
  margin-top: 64px !important;
}

.fullStoryBtn {
  display: none;
}

.whatsIn_section {
  z-index: 0;
  margin-top: 120px;
}

.whatsIn_singleSection {
  text-align: left;
  margin-top: 120px;
  display: flex;
  flex-direction: row;
}

.whatsIn_section_head {
  font-weight: 700;
  font-size: 58px;
  line-height: 70px;
  text-align: center;
  letter-spacing: 0.002em;
  color: #2F2F2F;
  /* margin-bottom: 64px; */
}

.home_service_main_head {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  text-align: center;
  color: #2F2F2F;
  margin-bottom: 32px;
}

.home_service_main_content {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #2F2F2F;
  margin-bottom: 40px;
  padding: 0px 100px;
}

.next_project_mobile {
  display: none;
}

.next_project_desktop {
  display: flex;
  margin-right: 15px !important;
}

.prev_project_mobile {
  display: none;
}

.prev_project_desktop {
  display: flex;
  margin-left: 15px !important;
}

.privacy_head {
  /* style={{textAlign:'left', marginTop:'72px', width:'100%', padding: '0px 20px'}} */
  text-align: left;
  margin-top: 72px;
  width: 100%;
  padding: 0px 20px 60px 15px;
}

.privacy_subhead {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 43px;
  letter-spacing: 0.0025em;
  color: #2F2F2F;
  margin-top: 40px;
  text-align: left;
  padding: 0px;
  font-family: 'Exo2-Regular';
  text-transform: uppercase;
}

.privacy_subContent {
  /* width: 66%; */
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 32px !important;
  color: #2F2F2F !important;
  margin-top: 30px !important;
  text-align: left !important;
  padding: 0px !important;
  font-family: 'NunitoSans-Regular';
}

.privacySubContent_row {
  text-align: left;
  padding: 0px 15px;
  width: 85% !important;
  margin-left: 15px !important;
}

.privacySubContent_lastrow {
  text-align: left;
  padding: 0px 15px;
  width: 75% !important;
  margin-left: 15px !important;
  margin-bottom: 120px;
}

.privacy_links {
  margin-top: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-decoration-line: underline;
  color: #0087FF;
  padding: 0px;
}

.privacy_links:hover {
  margin-top: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-decoration-line: underline;
  color: #0087FF;
}

.privacy_smallSubhead {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #2F2F2F;
  margin-top: 32px;
  padding: 0px;
}

.privacy_cookies_table_mobile {
 display: none;
}

.privacy_cookies_table_desktop {
  display: block;
  margin-top: 24px;
  padding: 0px;
}

.privacy_cookies_thead {
  background: #EEEEEE;
  border-radius: 20px;
  height: 64px;
  display: flex;
  /* flex-direction: row; */
  align-items: center;
}

.privacy_cookies_thead span {
  padding: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
}

.privacy_cookies_tbody span {
  /* padding: 16px; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  margin-top: 20px;
}

.privacy_ul {
  border-top-right-radius: 6px;
  /* border-top-left-radius: 6px; */
  /* border-bottom-left-radius: 6px; */
  border-bottom-right-radius: 6px;
  border-left: 2px solid #0087FF;
  /* width: 10px; */
  height: 96%;
  margin-top: 2%;
}

.privacy_li {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  margin-top: 15px;
}


@media only screen and (min-width: 1390px) { 
  /* img {
    max-width: 688px;
  } */

  .home_first_content {
    width: 97%;
    margin-left: 20px;
    /* height: 593px; */
    /* margin-bottom: 62px; */
  }

  .home_first_image img {
    /* margin-left: 365px; */
    position: absolute;
    top: 6%;
    width: 688px;
    color: #2F2F2F;
    left: 50%;
  }
  
  .for_bld_owners_img1 {
    max-width: 780px;
    margin-left: 5px;
    /* float: right; */
  }
  
  .for_bld_owners_img2 {
    max-width: 780px;
    margin-left: 10px;
  }
  
  .for_bld_owners_img3 {
    max-width: 780px;
    margin-left: 10px;
    /* float: right; */
  }
  
  .for_bld_owners_img4 {
    max-width: 780px;
    margin-left: 5px;
  }
  
  .for_bld_owners_img5 {
    max-width: 780px;
    margin-left: 13px;
    /* float: right; */
  }
  
  .for_bld_owners_content_box {
    margin-left: 20px;
  }
  
  .for_bld_owners_content_box2 {
    margin-left: 5px;
    /* float: right; */
  }
  
  .for_bld_owners_content_box3 {
    margin-left: 32px;
  }
  
  .for_bld_owners_content_box4 {
    margin-left: 15px;
  }
  
  .for_bld_owners_content_box5 {
    margin-left: 5px;
    /* float: right; */
  }
  
  .for_bld_owners_content_box6 {
    margin-left: 35px;
  }

  .sub_move_towards_content{
   width: 400px;
  }

 

  /* .home_first_content_head {
    width: 72%;
  } */


}

.service_bottom_contacthide {
  display: block;
}

.service_bottom_contact_us {
  display: none;
  background: #0087FF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
  width: 113px;
}



.service_stepper_container {
  text-align: left;
  margin-top: 120px;
}

.accordian_container {
  display: none;
}

.access_your_container {
  margin-top: 120px;
}

.col-lg-11 {
  padding: 0px;
}

.legal_head {
  padding: 0px 20px;
  text-align: left;
  margin-top: 75px;
}

.legal_address_container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.legal_address_first_content {
  margin-top: -10px;
}

.legal_address_second_content {
  margin-top: -10px;
  margin: 0px 80px;
}

.legal_address_third_content {
  margin-top: -10px;
}

@media (min-width: 200px) and (max-width: 545px){

  .container {
   /* padding: 0px 18px; */
   width: 95%;
  }

  .col-lg-6,  .col-lg-11 {
    padding: 0px;
  }

  .col-lg-4 {
    padding: 0px;
  }

  .col-lg-6, .col-md-12, .col-md-8, .col-sm-12 {
    padding: 0px;
  }

  .legal_head {
    padding: 0px;
  }

  .legal_address_container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .legal_address_first_content {
    margin-top: -10px;
  }
  
  .legal_address_second_content {
    margin-top: -10px;
    margin: 0px 0px;
  }
  
  .legal_address_third_content {
    margin-top: -10px;
  }

  .legal_content_container {
    padding: 0px 10px;
    margin-top: 40px;
  }

  .legal_subhead {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #2F2F2F;
  }
  
  .legal_subcontent {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    overflow-x: auto;
    margin-top: 16px !important;
  }

  .legal_content_address {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    margin-top: 64px !important;
  }
  
  /* home landing page */

  .mobile_slider {
    display: block;
  }
  
  .desktop_slider {
   display: none;
  }

  .slider_main_container {
    /* style={{backgroundColor:' #FAFBFC', height: '251px', marginTop:'80px'}} */
    background-color: #FFFFFF;
    height: 151px;
    margin-top: 0px;
  }

  .service_main_container {
    margin-top: 50px;
    margin-left: 5px;
    padding: 0px;
  }
  

  .slick-slider {
    width: 82%;
  }

  .slick-prev {
   z-index: 20;
   width: 51px;
   text-align: left;
   height: 51px;
   margin-left: -5px !important;
   background: linear-gradient(269.79deg, #FFFFFF 44.89%, rgba(255, 255, 255, 0.941718) 49.52%, rgba(255, 255, 255, 0.881105) 54.34%, rgba(255, 255, 255, 0.806504) 60.27%, rgba(255, 255, 255, 0.729976) 66.35%, rgba(255, 255, 255, 0.458333) 82.3%, rgba(255, 255, 255, 0) 101.03%);
  }

  .slick-next {
    z-index: 20;
    width: 82px;
    text-align: right;
    height: 51px;
    background: linear-gradient(269.78deg, #FFFFFF 44.35%, rgba(255, 255, 255, 0.941718) 48.93%, rgba(255, 255, 255, 0.881105) 53.69%, rgba(255, 255, 255, 0.806504) 59.54%, rgba(255, 255, 255, 0.729976) 65.55%, rgba(255, 255, 255, 0.458333) 81.31%, rgba(255, 255, 255, 0) 99.81%);
  }

  .slick-prev:before {
    content: url('./assest/Icons/ArrowSquareLeftsmall.png') !important;
    font-size: 52px;
    font-family: monospace !important;
  }
  
  .slick-next:before {
    content: url('./assest/Icons/ArrowSquareRightsmall.png') !important;
    font-size: 52px;
    font-family: monospace !important;
  }

  .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    background: linear-gradient(269.78deg, #FFFFFF 44.35%, rgba(255, 255, 255, 0.941718) 48.93%, rgba(255, 255, 255, 0.881105) 53.69%, rgba(255, 255, 255, 0.806504) 59.54%, rgba(255, 255, 255, 0.729976) 65.55%, rgba(255, 255, 255, 0.458333) 81.31%, rgba(255, 255, 255, 0) 99.81%);

  }

  .slick-slide {
    margin-top: 0px !important;
    margin-left: -25px;
  }

  .build_twins {
    width: 44px !important;
    height: 18px !important;
    margin-top: 10px;
    margin-left: 7px;
  }

  .build_twins_horn {
    width: 54px !important;
    height: 16px;
    cursor: pointer;
    margin-left: 5px;
    margin-top: 12px;
  }

  .build_twins:hover {
    -ms-transform: scale(0); 
    -webkit-transform: scale(0); 
    transform: scale(0) !important;
    /* height: 109px; */
  }
  
  .build_twins_horn:hover{
    -ms-transform: scale(0); 
    -webkit-transform: scale(0); 
    transform: scale(0) !important;
    /* height: 130px; */
  }
  
  
  .vatten:hover{
    -ms-transform: scale(0); 
    -webkit-transform: scale(0); 
    transform: scale(0) !important;
    /* height: 130px; */
  }

  .slick-slide .build_twins_horn_a { 
    width: 62px !important;
    display: inline-block !important;
    margin-left: 25px !important;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 36px;
    margin-left: 0px !important;
  }

  .slick-slide .phoenix_a { 
    width: 62px !important;
    display: inline-block !important;
    margin-left: 25px !important;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 36px;
    margin-left: 0px !important;
  }

  .slick-slide .hosh_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 36px;
    width: 62px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .bauer_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 36px;
    width: 62px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .bim_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 36px;
    width: 62px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .lubbing_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 36px;
    width: 62px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .wago_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 36px;
    width: 62px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .selve_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 36px;
    width: 62px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .vatten_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 36px;
    width: 62px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .volks_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 62px !important;
    height: 36px;
    margin-left: 0px !important;
  }
  
  .slick-slide .vono_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 62px !important;
    height: 36px;
    margin-left: 0px !important;
  }
  
  .slick-slide .bvg_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 62px !important;
    height: 36px;
    margin-left: 0px !important;
  }
  
  .slick-slide .fugitsu_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 62px !important;
    height: 36px;
    margin-left: 0px !important;
  }
  
  .slick-slide .gfr_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 62px !important;
    height: 36px;
    margin-left: 0px !important;
  }
  
  .slick-slide .hermos_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 62px !important;
    height: 36px;
    margin-left: 0px !important;
  }
  
  .slick-slide .lae_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 62px !important;
    height: 36px;
    margin-left: 0px !important;
  }
  
  
  .slick-slide .trio_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 62px !important;
    height: 36px;
    margin-left: 0px !important;
  }

  .gfr {
    width: 25px !important;
    margin-left: 18px;
  }

  .home_desktop_landing_page {
    margin-top: 10px;
    display: none;
  }

  .home_mobile_landing_page {
    display: block;
    margin-top: 70px;
  }

  .home_first_content_head {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.2px;
    width: 80%;
    margin-top: 29px;
    height: 100%;
  }

  .home_first_content_head_de {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.2px;
    width: 100%;
    margin-top: 29px;
  }

  .home_first_subcontent {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */
    letter-spacing: 0.3px;
    width: 100% !important;
    margin-top: 29px;
    margin-bottom: 32px;
  }

  .home_first_subcontent1 {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */
    letter-spacing: 0.3px;
    width: 100% !important;
    margin-top: 20px;
    margin-bottom: 32px;
  }

  .home_first_nums {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #2F2F2F;
  }
  
  .home_first_nums_p {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: #2F2F2F;
   }

   .home_first_content_number1 {
     width: 100%;
   }

   .home_first_content_number2 {
     width: 100%;
     margin: 0px 17px 0px 11px;
   }
   
   .home_first_content_number3 {
     width: 100%;
   }

  .home_first_image img {
    position: absolute;
    width: 95%;
    top: 420px;
    left: 12px;
  }

  .home_first_subhead {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    margin-bottom: 14px;
  }

  .home_first_content {
    margin-left: 0px;
  }

  
  .home_first_content_numberSection {
    display: flex;
    flex-direction: row;
  }

  .home_first_content_contact {
    margin-top: 0px; 
    text-align: center;
    margin-left: -35px;
  }

  .weCreatedHead {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #2F2F2F;
  }

  .home_service_main_head {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #2F2F2F;
    margin-bottom: 16px;
  }

  .home_service_main_content {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    padding: 0px 0px;
    margin-bottom: 32px;
  }

  .career_position_body {
    width: 100%;
    height: 100%;
    margin-top: 32px;
    /* margin-left: 8px; */
  }

  .aboutus_first_content_block {
    margin-top: 96px;
  }

  .aboutus_landing_toprow {
    background: none;
  }

  .aboutus_desc_container {
    margin-top: 64px;
    flex-direction: column-reverse;
  }

  .about_project_desc  {
    width: 100%;
    margin-left: 0px;
  }

  .about_project_img {
    width: 100%;
    height: 100%;
  }

  .aboutus_storyhead {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.2px;
  }

  .aboutus_storycontent {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.3px;
  }

  .reasonToCelebrate_topContent {
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
  }

  .coreValues_subContent_container {
    display: none;
  }

  .coreValues_container {
    margin-top: 64px;
  }

  .coreValues_mainContent  {
    padding: 0px;
  }

  .coreValues_mobile {
    display: flex;
    flex-direction: row;
  }

  .coreValues_desktop {
    display: none;
  }

  .aboutus_first_content_desktop {
    display: none;
  }

  .aboutus_first_content_mobile {
    display: block;
    padding: 0px;
  }


  .coreValues_subContent_img {
    width: 39px;
  }

  .coreValues_storycontent {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    text-transform: uppercase;
    color: #2F2F2F;
  }
  
  .reasonToCelebrate_container {
    margin-top: 64px;
  }

  .reasonToCelebrate_mainContent  {
    padding: 0px;
  }

  .reasonToCelebrate_subContent_container {
    padding: 0px;
    flex-direction: column;
  }

  .reasonToCelebrate_subContent_img {
    margin-bottom: 19px;
  }

  .reasonToCelebrate_subContent:hover .reasonToCelebrate_subContent_img{
    -ms-transform: none; 
    -webkit-transform: none; 
    transform: none !important;
    /* height: 109px; */
  }

  .reasonToCelebrate_year_text {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2F2F2F;
  }

  .reasonToCelebrate_storycontent {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #2F2F2F;
    width: 100% !important;
  }

  .cultivating_desc_mobile  {
    display: flex;
    flex-direction: row;
    margin-top: 64px;
  }

  .cultivating_desc_desktop  {
    display: none;
  }

  .cultivating_desc_container {
    margin-top: 64px;
  }

  .cultivating_project_desc {
    width: 100%;
    margin-left: 0px;
  }

  .cultivating_storyhead {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.2px;
    color: #2F2F2F;
    margin-bottom: 16px;
  }

  .cultivating_storycontent {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
  }

  .cultivating_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 64px;
  }


  .career_position_body_de {
    width: 100%;
    height: 150px;
    margin-top: 32px;
    padding: 9px;
    /* margin-left: 8px; */
  }

  /* .career_position_body:hover {
    background: #FFFFFF;
    box-shadow:0px 4px 4px rgba(0, 135, 255, 0.25);;
    border-radius: 20px;
  }
  
  .career_position_body_de:hover {
    background: #FFFFFF;
    box-shadow:0px 4px 4px rgba(0, 135, 255, 0.25);;
    border-radius: 20px;
  } */

  .sub_move_towards_body {
    width: 100%;
    height: 129px;
    margin-top: 32px;
    /* margin-left: 8px; */
  }

  .sub_move_towards_body_de {
    width: 100%;
    height: 150px;
    margin-top: 32px;
    padding: 9px;
    /* margin-left: 8px; */
  }

  .sub_move_towards_body:hover, .sub_move_towards_body:active {
    background: #FFFFFF;
    box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 20px;
  }
  
  .sub_move_towards_body_de:hover, .sub_move_towards_body_de:active {
    background: #FFFFFF;
    box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 20px;
  }

  .career_landing_toprow {
    background: none;
  }

  .career_position_container {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    padding: 0px 16px 16px 16px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    margin-top: 16px;
  }

  .career_position_container:hover, .career_position_container:active {
    background: #FFFFFF;
    box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 20px;
    /* height: 158px; */
    padding: 24px;
    text-align: left;
  }


  .career_position_body:hover, .career_position_body:active {
    margin-top: 0px;
  }

  .career_position_head:hover, .career_position_head:active {
    margin-top: 0px;
  }

  .career_position_content:hover, .career_position_content:active {
    margin-top: 0px;
  }

  .career_top_img {
    display: none;
  }

  .career_first_content_block {
    margin-top: 90px;
    margin-bottom: 32px;
  }

  .career_positions {
    margin-top: 32px;
  }

  .sub_move_towards_head  {
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #2F2F2F;
  }

  .career_positions_mainhead  {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #2F2F2F;
    margin-left: 0px;
    margin-bottom: 16px !important;
  }

  .career_position_head {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #2F2F2F;
    /* margin-left: 15px; */
  }

  .career_position_content {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #0087FF;
    margin-top: 8px;
    /* margin-left: 15px; */
  }

  .arrowicon {
    width: 18px;
  }

  .sub_move_towards_content {
    width: 98%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #2F2F2F;
    margin-left: 0px;
    margin-bottom: 15px;
  }

  .sub_move_towards_content_de {
    width: 98%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #2F2F2F;
    margin-left: 0px;
    margin-bottom: 15px;
  }


  .whatsIn_section {
    z-index: 0;
    margin-top: 64px;
  }

  .whatsIn_singleSection {
    text-align: left;
    margin-top: 64px;
    margin-left: 0px !important;
    display: flex;
    flex-direction: column;
    padding: 0px;
  }

  
  .whatsIn_firstSection {
    margin-left: 0px !important;
    /* padding-left: 0px; */
  }


  .whatsIn_section_head {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #2F2F2F;
    margin-bottom: 0px;
  }

  .for_bld_owners_content_box {
    margin-left: 0px;
    margin-top: 23px;
    width: 100%;
  }

 .for_bld_owners_content_box2, .for_bld_owners_content_box3 {
    margin-left: 0px;
    margin-top: 23px;
    width: 100%;
  }

  .for_bld_owners_content_box4, .for_bld_owners_content_box5 {
    margin-left: 0px;
    margin-top: 23px;
    width: 100%;
  }

  .for_bld_owners_content_box6 {
    margin-left: 0px;
    margin-top: 23px;
    width: 100%;
  }

  .box_property_asset {
   display: flex;
   flex-direction: column-reverse;
   margin-top: 60px !important;
  }

  .box_utility_providers {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 60px !important;
   }

  .for_bld_owners_content_caption {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
  }

  .for_bld_owners_content_head {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2F2F2F;
  }

  .for_bld_owners_content  {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    min-width: 295px;
  }

  .whatsIn_Button_containers {
    justify-content: center;
  }

  .whats_in_buttons {
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #2F2F2F;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 20px;
    font-weight: 400;
    font-size: 13px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 1.5px;
    color: #2F2F2F;
    padding: 0px 10px
  }

  
  .project_landing_desktop_img {
    display: none;
  }

  .project_landing_mobile_img {
    display: block;
    height: 100% !important;
  }
  

  .Home_section5_desktop_button1 {
    display: none;
  }
  
  .Home_section5_mobile_button1 {
    display: block;
  }
  
  .Home_section6_desktop_button1 {
    display: none;
  }
  
  .Home_section6_mobile_button1 {
    display: block;
  }
  
  .Home_section7_desktop_button1 {
    display: none;
  }
  
  .Home_section7_mobile_button1 {
    display: block;
  }

  .access_your_container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    margin-top: 48px;
  }

  .access_your_container button {
    margin-top: 40px !important;
    margin-bottom: 48px;
  }

  .access_your_img_container {
   height: 100%;
  }

  .access_your_head  {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #2F2F2F;
    margin-top: 0px;
  }

  .access_your_head_desktop {
    display: block;
  }
  
  .access_your_head_tab  {
    display: none;
  }

  .access_your_content {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    margin-top: 0px;
    width: 100%;
  }

  .weSupport_mainSection {
    margin: 48px 0px 64px 0px;
    /* margin-top: 48px; */
    /* margin-bottom: 64px; */
  }

  .weSupport {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
  }

  .weSupport_rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-left: 8px;
   
  }

  .weSupport_row1 {
    display: flex;
    flex-direction: column;
  }

  .weSupport_row2 {
    display: flex;
    flex-direction: column;
    margin-top: 0px !important;
  }

  .clientImageBox {
    width: 93%;
    height: 86px;
    margin-top: 15px;
    --tw-grayscale: grayscale(0%);
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 10px;
  }

  /* project landing page */

  .project_landing_toprow {
    background: none;
    margin-bottom: 60px;
  }

  .project_home {
    width: 103% !important;
    margin-left: 4px !important;
  }

  .project_desktop {
    display: none;
  }
  
  .project_mobile {
    display: block;
  }
  
  .project_tab {
    display: none;
  }

  .project_first_content_block {
    margin-top: 60px;
  }

  .project_landing_shortdesc {
    width: 100%;
  }

  .project_landing_blueHead {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #0087FF;
  }

  .project_landing_mainHead {
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.2px;
    width: 100% !important;
    margin-bottom: 16px !important;
    margin-top: 8px !important;
  }

  .project_landing_content {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.3px;
    max-width: 100%;
    margin-bottom:40px
  }

  .project_landing_content_de {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.3px;
    max-width: 100%;
    margin-bottom:40px
  }
  
 
  .project_landing_img {
   display: none;
 }

 .contact_us {
  /* width: 133px; */
  padding: 0px 16px;
  height: 42px;
  background: #0087FF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
 }

 .contact_us:hover, .contact_us:active { 
  background: #FFFFFF;
  box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  height: 42px;
  padding: 0px 10px;
  gap: 10px;
  border: 1px solid #2F2F2F;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #2F2F2F;
}

 .contact_us2 {
  /* width: 127px; */
  height: 42px;
  padding: 0px 24px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #2F2F2F;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #2F2F2F;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact_us2:hover, .contact_us2:active, .whats_in_buttons:hover, .whats_in_buttons:active {
  height: 42px;
  background: #0087FF;
  box-shadow: inset 0px 4px 4px rgba(47, 47, 47, 0.25);
  border-radius: 20px;
  padding: 0px 10px;
  gap: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
}




 .project_landing_single_section {
   text-align: left;
   /* margin-top: 54px; */
 }

 .project_outline {
   /* width: 100%; */
   /* margin-left: -20px; */
   flex-direction: column;
   /* margin-right: 4px; */
   border-radius: 0px !important;
   box-shadow: none;
 }

 .project_outline:hover {
  box-shadow: none;
  }

 .projectImage {
  min-width: 320px;
  width: 100% !important;
  /* height: 290px; */
  /* padding: 0px; */
  margin-left: -3px;
  border-radius: 0px !important;
  }

  .project_content_body  {
    margin-top: 32px;
    margin-left: 0px;
    width: 100%;
    padding: 0px 16px;
  }

  .project_blue_head {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #0087FF;
  }

  .project_head {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #2F2F2F;
  }

  .project_content {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .project_icon_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #2F2F2F;
    margin-left: 10px !important;
    margin-top: 4px;
  }

  .space-x-5 {
    margin-top: 16px !important;
  }

  .project_content_numbers {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #2F2F2F;
  }
  
  .project_no_text {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: #2F2F2F;
  }

  .get_in_touch_cotainer {
    background: #FAFBFC;
    padding: 28px 0px;
    margin-top: 0px;
    margin-bottom: 64px;
    text-align: center;
  }
  
  .get_in_touch_cotainer button {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  
  .get_in_touch_mobile .get_in_touch {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    margin-bottom: 20px;
  }
  
  .get_in_touch_mobile {
    display: block;
    margin-top: 28px;
    margin-bottom: 40px;
  }

  .get_in_touch_desktop {
    display: none;
  }

  .fullStoryBtn_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fullStoryBtn {
    display: block;
    padding: 0px 16px;
    gap: 10px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #2F2F2F;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 1.5px;
    color: #2F2F2F;
    margin: 64px 55px;
  }


  /* single project */
  .single_project_container {
    background: none;
  }

  .single_project_blue_head {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #0087FF;
  }

  .single_project_main_head  {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.2px;
    color: #2F2F2F;
    /* height: 30px; */
  }

  .single_project_main_head_de  {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.2px;
    color: #2F2F2F;
    /* height: 30px; */
    width: 100%;
  }

  .single_project_location_head {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #4EA0FD;
  }

  .single_project_content{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    width: 100% !important;
    margin-bottom: 0px !important;
  }

  .mann_project_content {
    margin-bottom: 0px;
  }

  .single_project_desc_image  {
    margin-left: 0px;
    width: 100% !important;
    border-radius: 0px;
    height: 100% !important;
  }

  .single_project_desc_image img{
    width: 100%;
    height: 300px;
  }

  .mann_project_content{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    width: 100% !important;
  }

  .mann_project_desc_image  {
    margin-left: 0px;
    width: 100% !important;
    border-radius: 0px;
    height: 100% !important;
  }

  .mann_project_desc_image img{
    width: 100%;
    height: 300px;
    height: 100% !important;
  }


  .single_project_number {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #2F2F2F;
    margin-top: 30px;
  }

  .single_project_number_text {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: #2F2F2F;
  }
  
  .allsteper, .allsteper2, .allsteper3 {
    display: none;
  }

  .single_project_desc_head {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 55px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #2F2F2F;
    margin-left: 0px;
    width: 100% !important;
  }

  .single_project_desc_p {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    width: 100% !important;
    margin-left: 0px;
    margin-top: 8px;
  }

  .mann_project_desc  {
   width: 100% !important;
   margin-left: 0px !important;
   height: 460px;
   padding: 10px;
   margin-top: 32px;
  }

  .results_benefits_subhead_desk {
    display: none;
  }

  .results_benefits_subhead_mob {
    display: block;
  }

  .mann_project_desc_subhead {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    margin-bottom: 19px;
  }

  .mann_project_desc_head {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 55px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #2F2F2F;
    width: 100% !important;
  }

  .mann_project_desc_head_de {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 55px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #2F2F2F;
    width: 100% !important;
  }


  .mann_project_desc_p {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    width: 100% !important;
  }

  .solution_container {
    text-align: left;
    margin-top: 0px;
  }

  .mann_solution_container {
    text-align: left;
    margin-top: 64px;
  }
  
  
  .solution_desc_head {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #2F2F2F;
    margin-left: 0px;
    width: 100%;
  }
  
  .solution_project_desc_p {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    margin-left: 0px;
    margin-top: 12px;
    margin-bottom: 16px;
    width: 100%;
  }

  .results_benefits_subhead {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    width: 100% !important;

  }

  .single_project_row_container {
    margin-top: 0px;
    background: none;
    height: 350px;
    padding: 0px;
  }

  .single_analytics_row_container {
    margin-top: 0px;
    background: none;
    height: 270px;
    padding: 0px;
  }

  .result_content {
    width: 100% !important;
    margin-left: 0px !important;
    text-align: left !important;
    align-items: left !important;
  }

  .single_project_desc {
    width: 100% !important;
    margin-left: 0;
    height: 100% !important;
    padding: 16px;
    margin-top: 32px;
  }

  .results_benefits_head{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #2F2F2F;
    margin-top: 24px;

  }

 
  .result_image {
    width: 100% !important;
    margin-left: 0px;
  }

  
  .resultContent_container {
    margin-top: 69px;
    margin-bottom: 67px;
  }
  


  .results_benefits_content {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
  }

  .results_benefits_subhead {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  
  .discover_how {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    padding: 0px;
    margin-bottom: 40px;
  }

  .wantToLearn {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    padding: 0px;
    margin-bottom: 40px;
  }


  .next_project_mobile {
    display: flex;
    margin-right: 15px !important;
  }

  .next_project_mobile img {
    margin-left: 8px !important;
  }
  
  .next_project_desktop {
    display: none;
  }
  
  .prev_project_mobile {
    display: flex;
    margin-left: 15px !important;
  }

  .prev_project_mobile p {
    margin-left: 8px !important;
  }
  
  .prev_project_desktop {
    display: none;
  }


   /* services */

   
  .single_service_block {
    text-align: left;
    margin-top: 100px;
    margin-left: 0px;
  }

  .single_service_content {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    width: 100% !important;
    margin-bottom: 32px !important;
    /* margin-top: 80px !important; */
  }

  .single_service_content_de {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    width: 100% !important;
    margin-bottom: 32px !important;
    /* margin-top: 110px; */
  }
 
  .analytics_single_service_content_de {
    /* margin-top: 65px !important; */
  }

  .single_service_analytics_content {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    width: 100% !important;
    margin-bottom: 32px !important;
    margin-top: 15px;
  }

  .service_benefits_section {
    margin-top: 120px;
    margin-bottom: 30px;
  }

  .control_service_content {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    width: 100% !important;
    margin-bottom: 32px !important;
    margin-top: 15px !important;
  }

.control_service_content_de {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
  width: 100% !important;
  margin-top: 15px !important;
  margin-bottom: 32px !important;
}

.service_benfits_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  height: 88%;
}

.service_benfits_content {
  min-width: 57%;
  max-width: 850px;
  /* height: 255px; */
  margin-left: 5px;
  /* display: flex;
  justify-content: space-between; */
  text-align: left;
}

.service_3d_head {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #2F2F2F;
  margin-top: 24px;
}

.service_contact_us {
  display: none;
}

.project_contact_us {
  display: none;
}

.service_bottom_contacthide {
  display: none;
}

.service_bottom_contact_us {
  display: block;
  background: #0087FF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
  width: 100%;
  padding: 0px 16px;
}

.service_stepper_container {
  text-align: left;
  margin-top: 50px;
  padding: 0px;
}

.accordian_container {
  display: block;
}

.accordion {
  padding: 0;
}

.accordion-item {
  margin-top: 16px;
  outline: none;
  border: none;
  box-shadow: none;
  border-radius: 10px !important;
}

.accordion-header {
  outline: none;
  box-shadow: none;
  border-radius: 10px !important;
}

.accordian-item {
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 10px !important;
}

.accordion-button, .accordion-button:focus {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 10px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #2F2F2F;
  outline: none;
  border: none;
  padding: 10px 12px;
  min-height: 75px;
  }

  .accordion-button:active, .accordion-button:not(.collapsed) {
    background-color: #FFFFFF;
    color: #2F2F2F;
    border: none;
    box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 10px;
    outline: none;
    padding: 24px 13px;
  } 

.card-body {
  display: flex;
  flex-direction: column-reverse;
  padding-top: 0px;
}

.card-body p {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
}

/* Custom style */
.solution_container .accordion-button::after {
  background-image: url("/src/assest/Icons2/plus.png");
  transform: scale(1.1) !important;
  padding-left: 15px;
}
.solution_container .accordion-button:not(.collapsed)::after {
  background-image: url("/src/assest/Icons2/download.png");
  transform: scale(1.1) !important;
  padding-left: 15px;
}

.mann_solution_container .accordion-button::after {
  background-image: url("/src/assest/Icons2/plus.png");
  transform: scale(1.1) !important;
  padding-left: 15px;
}
.mann_solution_container .accordion-button:not(.collapsed)::after {
  background-image: url("/src/assest/Icons2/download.png");
  transform: scale(1.1) !important;
  padding-left: 15px;
}

.service_stepper_container .accordion-button::after {
  background-image: url("/src/assest/Icons2/plus.png");
  transform: scale(1.1) !important;
  padding-left: 15px;
}
.service_stepper_container .accordion-button:not(.collapsed)::after {
  background-image: url("/src/assest/Icons2/download.png");
  transform: scale(1.1) !important;
  padding-left: 15px;
}

.contact_form_meeting_lang_mobile {
  align-items: left;
  margin-bottom: 24px;
  margin-top: 10px;
}



.position_first_content_block {
  width: 100%;
  margin-top: 145px;
  max-width: 100%;
  text-align: left;
  padding: 0px 10px;
}

.position_blueHead {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #0087FF;
}

.position_mainHead {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.2px;
  color: #2F2F2F;
}

.position_subblueHead {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 7px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: #4EA0FD;
}

.positionFirst_content_subhead {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #2F2F2F;
}

.positionFirst_content_subcontent {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
  color: #2F2F2F;
}

.positionFirst_content_middle_subcontent {
  display: flex;
  flex-direction: column;
  margin: 0px 37px;
}


.position_second_content_block {
  margin-top: 40px;
  padding: 0px 10px;
}

.position_about_leaftech_head {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #2F2F2F;
}

.position_about_leaftech_content {
  margin-top: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
}

.position_third_content_subcontent {
  margin-top: 40px;
  padding: 0px 10px;
}

.responsibility_and_expect {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.resEexpect_content_block {
  display: flex;
  padding: 0px;
  width: 100%;
}
.mobile {
  display: block;
}

.desktop {
  display: none;
}


.sideline_ul {
  height: 97%;
  margin-top: 12px;
  display: none;
}

.p1_sideline_ul1 {
  display: none;
  height: 410px;
  margin-top: 15px;
  width: 4px;
}

.p1_sideline_ul2 {
  display: none;
  height: 410px;
  margin-top: 15px;
  width: 7px;
}

.p1_sideline_ul3 {
  display: none;
  height: 340px;
  margin-top: 15px;
  width: 4px;
}

.p1_sideline_ul4 {
  display: none;
  height: 350px;
  margin-top: 15px;
  width: 7px;
}

.p2_sideline_ul1 {
  display: none;
  height: 325px;
  margin-top: 15px;
  width: 4px;
}

.p2_sideline_ul2 {
  display: none;
  height: 288px;
  margin-top: 15px;
  width: 5px;
}

.p2_sideline_ul3 {
  display: none;
  height: 350px;
  margin-top: 15px;
  width: 4px;
}

.p2_sideline_ul4 {
  display: none;
  height: 350px;
  margin-top: 15px;
  width: 5px;
}



.position_resExpect_li {
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2F2F2F;
  padding: 0px 0px 0px 12px;
}

.position_resExpect_li::before {content: "•"; color: #0087FF;
  display: inline-block; width: 1em;
  margin-left: -1em
}

.position_fourth_content_block {
  margin-top: 40px;
  padding: 0px 10px;
}

.position_fifth_content_block {
  margin-top: 40px;
  padding: 0px 10px;
}

.weOffer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}


.weOffer_content_block {
  display: flex;
  padding: 0px;
}


.position_weOffer_li {
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2F2F2F;
  padding: 0px 0px 0px 15px;
}

.position_weOffer_li::before {content: "•"; color: #0087FF;
  display: inline-block; width: 1em;
  margin-left: -1em
}


.weOffer_sideline {
  height: 190px;
  width: 2px;
  display: none;
}

.position_sixth_content_block {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0px 10px;
  display: flex;
}

.moreInfo_container {
  display: flex;
  flex-direction: column;
}

.position_moreInfo {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
  margin-bottom: 40px;
}

.position_moreInfo_img_container {
  width: 115%;
}

.position_moreInfo_img {
 width: 69px;
 margin-left: 3px;
}


.privacy_landing_toprow {
  background: none;
  margin-bottom: 0px;
  margin-top: 50px;
  min-height: initial;
}

.privacy_head {
  text-align: left;
  margin-top: 72px;
  width: 100%;
  padding: 0px;
}

.privacy_subhead {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #2F2F2F;
  margin-top: 30px;
  text-align: left;
  padding: 0px;
}

.privacy_subContent {
  /* width: 66%; */
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  letter-spacing: 0.3px !important;
  color: #2F2F2F;
  padding: 0px !important;
  margin-top: 16px !important;
}

.privacySubContent_row {
  text-align: left;
  padding: 0px 10px !important;
  width: 100% !important;
  margin-left: 0px !important;
}

.privacySubContent_lastrow {
  text-align: left;
  padding: 0px 15px;
  width: 75% !important;
  margin-left: 15px !important;
  margin-bottom: 120px;
}

.privacy_links {
  margin-top: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #0087FF;
  padding: 0px;
}

.privacy_links:hover {
  margin-top: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #0087FF;
}

.privacy_smallSubhead {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #2F2F2F;
  margin-top: 16px !important;
  padding: 0px;
}



.privacy_cookies_table_desktop {
  display: none;
  margin-top: 24px;
  padding: 0px;
}

.privacy_cookies_table_mobile {
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin-bottom: 24px;
}

.privacy_cookies_thead {
  background: #EEEEEE;
  border-radius: 20px;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 14px;
}

.privacy_cookies_thead span {
  padding: 8px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #2F2F2F;
  /* margin-top: 35px; */
  
}

.privacy_cookies_tbody {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.privacy_cookies_tbody span {
  padding: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
  /* margin-top: 35px; */
}

.privacy_ul {
  border-top-right-radius: none;
  /* border-top-left-radius: none; */
  /* border-bottom-left-radius: none; */
  border-bottom-right-radius: none;
  border-left: none;
  /* width: 10px; */
  height: 96%;
  margin-top: 24px;
}

.privacy_li {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2F2F2F;
  margin-top: 15px;
}

.privacy_li::before {content: "•"; color: #0087FF;
  display: inline-block; width: 1em;
  margin-left: -1em
}



}

@media (min-width: 555px) and (max-width: 1180px){

  .container {
   /* padding: 0px 18px; */
   width: 95%;
  }

  .col-lg-6 {
    padding: 0px;
  }

  .col-lg-4 {
    padding: 0px;
  }

  ul {
    width: 100%;
  }

  .legal_head {
    padding: 0px;
  }

  .legal_content_container {
    padding: 0px 0px;
    margin-top: 40px;
  }

  .legal_subhead {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #2F2F2F;
  }
  
  .legal_subcontent {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
  }

  /* home landing page */

  .mobile_slider {
    display: block;
  }
  
  .desktop_slider {
   display: none;
  }

  .slider_main_container {
    /* style={{backgroundColor:' #FAFBFC', height: '251px', marginTop:'80px'}} */
    background-color: #FFFFFF;
    /* height: 151px; */
    margin-top: 0px;
  }

  .service_main_container {
    margin-top: 50px;
    margin-left: 5px;
    padding: 0px;
  }
  

  .slick-slider {
    width: 92%;
  }

  .slick-prev {
   z-index: 20;
   width: 51px;
   text-align: left;
   height: 51px;
   background: linear-gradient(269.79deg, #FFFFFF 44.89%, rgba(255, 255, 255, 0.941718) 49.52%, rgba(255, 255, 255, 0.881105) 54.34%, rgba(255, 255, 255, 0.806504) 60.27%, rgba(255, 255, 255, 0.729976) 66.35%, rgba(255, 255, 255, 0.458333) 82.3%, rgba(255, 255, 255, 0) 101.03%);
  }

  .slick-next {
    z-index: 20;
    width: 82px;
    text-align: right;
    height: 51px;
    background: linear-gradient(269.78deg, #FFFFFF 44.35%, rgba(255, 255, 255, 0.941718) 48.93%, rgba(255, 255, 255, 0.881105) 53.69%, rgba(255, 255, 255, 0.806504) 59.54%, rgba(255, 255, 255, 0.729976) 65.55%, rgba(255, 255, 255, 0.458333) 81.31%, rgba(255, 255, 255, 0) 99.81%);
  }

  .slick-prev:before {
    content: url('./assest/Icons/ArrowSquareLeft.png') !important;
    font-size: 52px;
    position: relative;
    /* font-family: monospace !important; */
    top: -11px;
    left: -30px;
  }
  
  .slick-next:before {
    content: url('./assest/Icons/ArrowSquareRight.png') !important;
    font-size: 52px;
    font-family: monospace !important;
    position: relative;
    /* font-family: monospace !important; */
    top: -9px;
    /* left: -30px; */
  }

  .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    background: linear-gradient(269.78deg, #FFFFFF 44.35%, rgba(255, 255, 255, 0.941718) 48.93%, rgba(255, 255, 255, 0.881105) 53.69%, rgba(255, 255, 255, 0.806504) 59.54%, rgba(255, 255, 255, 0.729976) 65.55%, rgba(255, 255, 255, 0.458333) 81.31%, rgba(255, 255, 255, 0) 99.81%);

  }

  .slick-slide {
    margin-top: 0px !important;
    width: 158px !important;
  }

  .build_twins {
    width: 60px !important;
    height: 20px !important;
    margin-left: 18px;
  }

  .build_twins_horn {
    width: 80px !important;
    height: 10px;
    cursor: pointer;
    margin-left: 8px;
    margin-top: 25px;
  }

  .build_twins:hover {
    -ms-transform: scale(0); 
    -webkit-transform: scale(0); 
    transform: scale(0) !important;
    /* height: 109px; */
  }
  
  .build_twins_horn:hover{
    -ms-transform: scale(0); 
    -webkit-transform: scale(0); 
    transform: scale(0) !important;
    /* height: 130px; */
  }
  
  
  .vatten:hover{
    -ms-transform: scale(0); 
    -webkit-transform: scale(0); 
    transform: scale(0) !important;
    /* height: 130px; */
  }

  .slick-slide .build_twins_horn_a { 
    width: 96px !important;
    display: inline-block !important;
    margin-left: 25px !important;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 56px;
    margin-left: 0px !important;
  }

  .slick-slide .phoenix_a { 
    width: 96px !important;
    display: inline-block !important;
    margin-left: 25px !important;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 56px;
    margin-left: 0px !important;
  }

  .slick-slide .hosh_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 56px;
    width: 96px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .bauer_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 56px;
    width: 96px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .bim_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 56px;
    width: 96px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .lubbing_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 56px;
    width: 96px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .wago_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 56px;
    width: 96px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .selve_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 56px;
    width: 96px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .vatten_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    height: 56px;
    width: 96px !important;
    display: inline-block !important;
    margin-left: 0px !important;
  }
  
  .slick-slide .volks_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 96px !important;
    height: 56px;
    margin-left: 0px !important;
  }
  
  .slick-slide .vono_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 96px !important;
    height: 56px;
    margin-left: 0px !important;
  }
  
  .slick-slide .bvg_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 96px !important;
    height: 56px;
    margin-left: 0px !important;
  }
  
  .slick-slide .fugitsu_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 96px !important;
    height: 56px;
    margin-left: 0px !important;
  }
  
  .slick-slide .gfr_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 96px !important;
    height: 56px;
    margin-left: 0px !important;
  }
  
  .slick-slide .hermos_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 96px !important;
    height: 56px;
    margin-left: 0px !important;
  }
  
  .slick-slide .lae_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 96px !important;
    height: 56px;
    margin-left: 0px !important;
  }
  
  
  .slick-slide .trio_a{
     background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 135 255 / 25%);
    border-radius: 10px;
    margin-top: 45px;
    width: 96px !important;
    height: 56px;
    margin-left: 0px !important;
  }

  .gfr {
    width: 30px !important;
    margin-left: 32px;
  }

  .home_desktop_landing_page {
    margin-top: 10px;
    display: block;
    background: linear-gradient(90.18deg, #FFFFFF 0.14%, rgba(255, 255, 255, 0.734375) 55.18%, rgba(255, 255, 255, 0.4) 66.4%, rgba(255, 255, 255, 0) 99.84%);
  }

  .home_mobile_landing_page {
    display: none;
    margin-top: 70px;
  }

  .home_first_content_head {
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 109%;
    color: #2F2F2F;
    width: 100%;
    margin-top: 29px;
    height: 100%;
  }

  .home_first_content_head_de {
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 109%;
    color: #2F2F2F;
    width: 100%;
    margin-top: 29px;
  }

  .home_first_subcontent {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    mix-blend-mode: normal;
    width: 100% !important;
    margin-top: 29px;
    margin-bottom: 32px;
  }

  .home_first_subcontent1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    mix-blend-mode: normal;
    width: 100% !important;
    margin-top: 40px;
    margin-bottom: 32px;
  }

  .home_first_nums {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-transform: capitalize;
    color: #2F2F2F;
  }
  
  .home_first_nums_p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #2F2F2F;
   }

   .home_first_content_number1 {
    height: 58px;
    width: 100%;
   }

   .home_first_content_number2 {
     height: 58px;
     width: 100%;
     margin: 0px 30px 0px 20px;
    }
   
   .home_first_content_number3 {
    height: 58px;
    width: 100%;
   }

  .home_first_image img {
    position: absolute;
    width: 50%;
    top: 160px;
    left: 46% !important;
    z-index: -12;
  }

  .home_first_subhead {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: #2F2F2F;
    margin-bottom: 14px;
  }

  .home_first_content {
    margin-left: 0px;
    width: 58% !important;
    margin-top: 135px;
  }

  
  .home_first_content_numberSection {
    display: flex;
    flex-direction: row;
    width: 470px;
  }

  
  .home_first_content_number1_de {
    width: 315px;
    height: 74px;
    /* style={{width:'190px', height:'74px'}} */
  }

  .home_first_content_contact {
    margin-top: 0px;
    text-align: left;
    margin-left: 0px;
  }

  .weCreatedHead {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #2F2F2F;
  }

  .home_service_main_head {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    color: #2F2F2F;
    margin-bottom: 16px;
  }

  .home_service_main_content {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #2F2F2F;
    padding: 0px 0px;
    margin-bottom: 32px;
  }

  .career_position_body {
    width: 100%;
    height: 100%;
    margin-top: 32px;
    /* margin-left: 8px; */
  }

  .aboutus_first_content_block {
    margin-top: 96px;
    padding: 0px;
  }

  .aboutus_landing_toprow {
    background: none;
  }

  .aboutus_desc_container {
    margin-top: 64px;
    flex-direction: column-reverse;
  }

  .about_project_desc  {
    width: 100%;
    margin-left: 0px;
  }

  .about_project_img {
    width: 100%;
    height: 100%;
  }

  .about_project_img img{
    width: 100%;
    height: 100%;
  }

  .aboutus_storyhead {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.2px;
  }

  .aboutus_storycontent {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
  }

  .coreValues_subContent_container {
    display: none;
  }

  .coreValues_container {
    margin-top: 64px;
  }

  .coreValues_mainContent  {
    padding: 0px;
  }

  .coreValues_mobile {
    display: none;
  }

  .coreValues_desktop {
    display: flex;
    flex-direction: row;
  }

  .aboutus_first_content_desktop {
    display: none;
  }

  .aboutus_first_content_mobile {
    display: block;
    padding: 0px;
  }


  .coreValues_subContent_img {
    width: 39px;
  }

  .coreValues_storycontent {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    text-transform: uppercase;
    color: #2F2F2F;
  }
  
  .reasonToCelebrate_container {
    margin-top: 64px;
  }

  .reasonToCelebrate_mainContent  {
    padding: 0px;
  }

  .reasonToCelebrate_subContent_container {
    padding: 0px;
    flex-direction: row;
  }

  .reasonToCelebrate_subContent_img {
    margin-bottom: 19px;
  }

  .reasonToCelebrate_subContent:hover .reasonToCelebrate_subContent_img{
    -ms-transform: none; 
    -webkit-transform: none; 
    transform: none !important;
    /* height: 109px; */
  }

  .reasonToCelebrate_year_text {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2F2F2F;
  }

  .reasonToCelebrate_storycontent {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #2F2F2F;
    width: 100% !important;
  }

  .cultivating_desc_mobile  {
    display: flex;
    flex-direction: row;
    margin-top: 64px;
  }

  .cultivating_desc_desktop  {
    display: none;
  }

  .cultivating_desc_container {
    margin-top: 64px;
  }

  .cultivating_project_desc {
    width: 100%;
    margin-left: 0px;
    display: flex;
    flex-direction: column-reverse;
  }

  .cultivating_storyhead {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.2px;
    color: #2F2F2F;
    margin-bottom: 16px;
    margin-top: 21px;
  }

  .cultivating_storycontent {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
  }

  .cultivating_button {
    display: flex;
    align-items: center;
    justify-content: left;
    /* justify-content: left; */
    margin-top: 40px;
    margin-bottom: 64px;
  }


  .career_position_body_de {
    width: 100%;
    height: 150px;
    margin-top: 32px;
    padding: 9px;
    /* margin-left: 8px; */
  }

  /* .career_position_body:hover {
    background: #FFFFFF;
    box-shadow:0px 4px 4px rgba(0, 135, 255, 0.25);;
    border-radius: 20px;
  }
  
  .career_position_body_de:hover {
    background: #FFFFFF;
    box-shadow:0px 4px 4px rgba(0, 135, 255, 0.25);;
    border-radius: 20px;
  } */

  .sub_move_towards_body {
    width: 95%;
    height: 145px;
    margin-top: 32px;
    margin-left: 8px;
    padding: 0px 10px;
  }

  .sub_move_towards_body_de {
    width: 90%;
    height: 170px;
    margin-top: 32px;
    margin-left: 8px;
    padding: 0px 13px;
  }

  .sub_move_towards_body:hover, .sub_move_towards_body:active {
    background: #FFFFFF;
    box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 20px;
  }
  
  .sub_move_towards_body_de:hover, .sub_move_towards_body_de:active {
    background: #FFFFFF;
    box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 20px;
  }

  .career_landing_toprow {
    background: none;
  }

  .career_position_container {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    margin-top: 16px;
    margin-left: 16px;
  }

  .career_position_container:hover, .career_position_container:active {
    background: #FFFFFF;
    box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 20px;
    /* height: 158px; */
    /* padding: 24px; */
    text-align: left;
  }


  .career_top_img {
    display: none;
  }

  .career_first_content_block {
    margin-top: 90px;
    margin-bottom: 32px;
    padding: 0px;
  }

  .items-left {
    padding: 0px;
  }

  .career_positions {
    margin-top: 32px;
  }

  .sub_move_towards_head  {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #2F2F2F;
  }

  .career_positions_mainhead  {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #2F2F2F;
    margin-left: 15px;
  }

  .career_position_head {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #2F2F2F;
    /* margin-left: 15px; */
  }

  .career_position_content {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #0087FF;
    margin-top: 8px;
    /* margin-left: 15px; */
  }

  .arrowicon {
    width: 24px;
  }

  .sub_move_towards_content {
    width: 98%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #2F2F2F;
    margin-left: 0px;
    margin-bottom: 15px;
  }

  .sub_move_towards_content_de {
    width: 98%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #2F2F2F;
    margin-left: 0px;
    margin-bottom: 15px;
  }


  .whatsIn_section {
    z-index: 0;
    margin-top: 64px;
  }

  .whatsIn_singleSection {
    text-align: left;
    margin-top: 64px;
    margin-left: 0px !important;
    display: flex;
    flex-direction: column;
  }

  
  .whatsIn_firstSection {
    margin-left: 0px !important;
    /* padding-left: 0px; */
  }


  .whatsIn_section_head {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    text-transform: capitalize;
    color: #2F2F2F;
    margin-bottom: 0px;
  }

  .for_bld_owners_content_box {
    margin-left: 0px;
    margin-top: 23px;
    width: 100%;
  }

 .for_bld_owners_content_box2, .for_bld_owners_content_box3 {
    margin-left: 0px;
    margin-top: 23px;
    width: 100%;
  }

  .for_bld_owners_content_box4, .for_bld_owners_content_box5 {
    margin-left: 0px;
    margin-top: 23px;
    width: 100%;
  }

  .for_bld_owners_content_box6 {
    margin-left: 0px;
    margin-top: 23px;
    width: 100%;
  }

  .box_property_asset {
   display: flex;
   flex-direction: column-reverse;
   margin-top: 60px !important;
  }

  .box_utility_providers {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 60px !important;
   }

  .for_bld_owners_content_caption {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #0087FF;
  }

  .for_bld_owners_content_head {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-transform: capitalize;
    color: #2F2F2F;
  }

  .for_bld_owners_content  {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    min-width: 295px;
  }

  .whatsIn_Button_containers {
    justify-content: left;
  }


  .whats_in_buttons {
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #2F2F2F;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 1.5px;
    color: #2F2F2F;
    padding: 0px 20px
  }

  
  .project_landing_desktop_img {
    display: block;
  }

  .project_landing_mobile_img {
    display: none;
  }

  .Home_section5_desktop_button1 {
    display: block;
  }

  
  .project_landing_tab_img {
    display: block;
  }

  .project_notTab_img {
    display: none;
  }

  
  .Home_section5_mobile_button1 {
    display: none;
  }
  
  .Home_section6_desktop_button1 {
    display: none;
  }
  
  .Home_section6_mobile_button1 {
    display: block;
  }
  
  .Home_section7_desktop_button1 {
    display: none;
  }
  
  .Home_section7_mobile_button1 {
    display: block;
  }

  .access_your_container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    margin-top: 48px;
  }

  .access_your_content_container {
    width: 100%;
  }

  .access_your_container button {
    margin-top: 40px !important;
    margin-bottom: 48px;
  }

  .access_your_img_container {
   height: 100%;
  }

  .access_your_head_desktop {
    display: none;
  }
  
  .access_your_head_tab  {
    display: block;
  }

  .access_your_head  {
    /* height: 50px; */
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    /* line-height: 90px; */
    text-align: center;
    color: #2F2F2F;
    margin-top: 0px;
  }

  .access_your_content {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #2F2F2F;
    margin-top: 0px;
    width: 100%;
  }

  .weSupport_mainSection {
    margin: 48px 0px 64px 0px;
    /* margin-top: 48px; */
    /* margin-bottom: 64px; */
  }

  .weSupport {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
  }

  /* .weSupport_rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-left: 8px;
   
  }

  .weSupport_row1 {
    display: flex;
    flex-direction: column;
  }

  .weSupport_row2 {
    display: flex;
    flex-direction: column;
    margin-top: 0px !important;
  } */

  .clientImageBox {
    width: 164px;
    height: 90px;
    margin-top: 15px;
    --tw-grayscale: grayscale(0%);
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 10px;
    padding: 15px;
    /* margin-left: 16px; */
  }

  /* project landing page */
  
  .project_landing_toprow {
    background: none;
    margin-bottom: 60px;
  }

  .project_home {
    width: 100% !important;
    margin-left: 1px !important;
  }

  .project_desktop {
    display: none;
  }
  
  .project_mobile {
    display: none;
  }
  
  .project_tab {
    display: block;
  }

  .project_first_content_block {
    margin-top: 60px;
  }

  .project_landing_shortdesc {
    width: 100%;
  }

  .project_landing_blueHead {
    font-size: 20px !important;
  }

  .project_landing_mainHead {
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 109%;
    color: #2F2F2F;
    width: 100% !important;
  }

  .project_landing_content {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    mix-blend-mode: normal;
    max-width: 100%;
    margin-bottom:40px
  }

  .project_landing_content_de {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    mix-blend-mode: normal;
    max-width: 100%;
    margin-bottom:40px
  }
  
 
  .project_landing_img {
   display: block;
 }

 .contact_us {
  /* width: 133px; */
  padding: 0px 16px;
  height: 42px;
  background: #0087FF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
 }

 .contact_us:hover, .contact_us:hover {
  /* width: 133px; */
  padding: 0px 16px !important;
  height: 42px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #2F2F2F;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #2F2F2F;
 }

 .contact_us2 {
  /* width: 127px; */
  height: 42px;
  padding: 0px 10px !important;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #2F2F2F;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #2F2F2F;
  flex: none;
  order: 0;
  flex-grow: 0;
}

 .contact_us2:hover, .contact_us2:active, .whats_in_buttons:hover, .whats_in_buttons:active {
  height: 42px;
  background: #0087FF;
  box-shadow: inset 0px 4px 4px rgba(47, 47, 47, 0.25);
  border-radius: 20px;
  padding: 0px 10px !important;
  gap: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
}

 .project_landing_single_section {
   text-align: left;
   /* margin-top: 54px; */
 }

 .project_outline {
   width: 99%;
   margin-left: 3px;
   /* margin-right: 4px; */
   border-radius: 20px !important;
   height: 420px !important;
   /* box-shadow: none; */
 }

 .project_outline:hover {
    box-shadow: none;
  }

 .projectImage {
    min-width: 320px;
    width: 100% !important;
    /* height: 74% !important; */
    height: 330px !important;
    /* padding: 0px; */
    margin-left: 0px;
    border-radius: 20px !important;
  }

  .project_content_body  {
    margin-top: 20px;
    margin-left: 0px;
    width: 100%;
    padding: 0px 16px;
    height: 100%;
  }

  .project_blue_head {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #0087FF;
  }

  .project_head {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-transform: capitalize;
    color: #2F2F2F;
  }

  .project_content {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #2F2F2F;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .project_outline .project_icon_text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #2F2F2F;
    margin-top: 0px;
  }

  .project_icon_text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.0025em;
    color: #2F2F2F;
    margin-top: 0px;
  }
  
  .project_imageNum_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .space-x-5 {
    margin-top: 16px !important;
  }

  .project_content_numbers {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #2F2F2F;
  }
  
  .project_no_text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #2F2F2F;
  }

  .get_in_touch_cotainer {
    background: #FAFBFC;
    padding: 28px 0px;
    margin-top: 0px;
    margin-bottom: 64px;
    text-align: center;
  }
  
  .get_in_touch_cotainer button {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  
  .get_in_touch_mobile .get_in_touch {
    font-weight: 400;
    width: 400px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    margin-bottom: 20px;
  }

  .get_in_touch_de {
    width: 535px !important;
  }
  
  .get_in_touch_mobile {
    display: flex;
    margin-top: 28px;
    margin-bottom: 40px;
  }

  .get_in_touch_desktop {
    display: none;
  }

  .fullStoryBtn_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fullStoryBtn {
    display: block;
    padding: 0px 16px;
    gap: 10px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #2F2F2F;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    border-radius: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 1.5px;
    color: #2F2F2F;
    margin: 64px;
  }


  /* single project */
  
  .single_project_blue_head {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: #0087FF;
  }

  .single_project_main_head  {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 0.0025em;
    color: #2F2F2F;
    margin-bottom: 30px;
  }

  .single_project_main_head_de  {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 0.0025em;
    color: #2F2F2F;
    width: 100%;
  }

  .single_project_location_head {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #4EA0FD;
  }

  .single_project_content{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    mix-blend-mode: normal;
    width: 100% !important;
    margin-bottom: 0px !important;
  }

  .mann_project_content {
    margin-bottom: 0px;
  }

  .single_project_desc_image  {
    margin-left: 0px;
    margin-top: 64px;
    width: 100% !important;
    border-radius: 0px;
    height: 100% !important;
    padding: 0px 30px;
  }

  .single_project_desc_image img{
    width: 100%;
    height: 50%;
    /* height: 525px; */
    border-radius: 20px !important;
  }

  .mann_project_content{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    mix-blend-mode: normal;
    width: 100% !important;
  }

  .mann_project_desc_image  {
    margin-left: 0px;
    width: 100% !important;
    border-radius: 0px;
    height: 100% !important;
    padding: 10px 32px;
  }

  .mann_project_desc_image img{
    width: 100%;
    /* height: 300px; */
    height: 100% !important;
    border-radius: 20px !important;
  }


  .single_project_number {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.0025em;
    text-transform: lowercase;
    color: #2F2F2F;
    margin-top: 30px;
  }

  .single_project_number_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #2F2F2F;
  }
  
  .allsteper, .allsteper2, .allsteper3 {
    display: none;
  }

  .single_project_desc_head {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-transform: capitalize;
    color: #2F2F2F;
    margin-bottom: 0px;
    margin-left: 0px;
    width: rgb(121, 119, 119)mportant;
  }

  .single_project_desc_p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    width: 100% !important;
    margin-left: 0px;
    margin-top: 8px;
  }

  .mann_project_desc  {
   width: 100% !important;
   margin-left: 0px !important;
   height: 460px;
   padding: 10px 32px;
   margin-top: 32px;
  }

  .results_benefits_subhead_desk {
    display: block;
  }

  .results_benefits_subhead_mob {
    display: none;
  }

  .mann_project_desc_subhead {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    margin-bottom: 19px;
  }

  .mann_project_desc_head {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-transform: capitalize;
    color: #2F2F2F;
    width: 100% !important;
  }

  .mann_project_desc_head_de {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-transform: capitalize;
    color: #2F2F2F;
    width: 100% !important;
  }


  .mann_project_desc_p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    width: 100% !important;
  }

  .solution_container {
    text-align: left;
    margin-top: 0px;
  }

  .mann_solution_container {
    text-align: left;
    margin-top: 64px;
  }
  
  
  .solution_desc_head {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-transform: capitalize;
    color: #2F2F2F;
    margin-left: 0px;
    width: 100%;
  }
  
  .solution_project_desc_p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    margin-left: 0px;
    margin-top: 12px;
    margin-bottom: 16px;
    width: 100%;
  }

  .results_benefits_subhead {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    width: 100% !important;
  }

  .single_project_row_container {
    margin-top: 90px;
    background: none;
    height: 315px;
    padding: 0px;
  }

  .single_analytics_row_container {
    margin-top: 0px;
    background: none;
    height: 270px;
    padding: 0px;
  }

  .result_content {
    width: 100% !important;
    margin-left: 0px !important;
    text-align: left !important;
    align-items: left !important;
    margin-top: 36px;
  }

  .single_project_desc {
    width: 100% !important;
    margin-left: 0;
    height: 100% !important;
    padding: 10px 32px;
    margin-top: 0px !important;
  }

  .results_benefits_head{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-transform: capitalize;
    color: #2F2F2F;
    margin-top: 24px;
  }

 
  .result_image {
    width: 100% !important;
    margin-left: 0px;
  }

  
  .resultContent_container {
    margin-top: 69px;
    margin-bottom: 67px;
    display: flex;
    flex-direction: column;
  }

  


  .results_benefits_content {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
  }

  .results_benefits_subhead {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  
  .discover_how {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    padding: 0px;
    margin-bottom: 40px;
  }

  .wantToLearn {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    padding: 0px;
    margin-bottom: 40px;
  }


  .next_project_mobile {
    display: none;
    margin-right: 15px !important;
  }

  .next_project_mobile img {
    margin-left: 8px !important;
  }
  
  .next_project_desktop {
    display: flex;
  }
  
  .prev_project_mobile {
    display: none;
    margin-left: 15px !important;
  }

  .prev_project_mobile p {
    margin-left: 8px !important;
  }
  
  .prev_project_desktop {
    display: flex;
  }

  .single_project_container {
    background: none;
  }

   /* services */

   
  .single_service_block {
    text-align: left;
    margin-top: 100px;
    margin-left: 0px;
    padding: 0px;
  }

  .single_service_content {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    mix-blend-mode: normal;
    width: 100% !important;
    margin-bottom: 32px !important;
    /* margin-top: 80px !important; */
  }

  .single_service_content_de {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    width: 100% !important;
    margin-bottom: 32px !important;
    margin-top: 35px;
  }
 
  .analytics_single_service_content_de {
    margin-top: 35px !important;
  }

  .single_service_analytics_content {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    mix-blend-mode: normal;
    width: 100% !important;
    margin-bottom: 32px !important;
    margin-top: 15px;
  }

  .service_benefits_section {
    margin-top: 120px;
    margin-bottom: 30px;
  }

  .control_service_content {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #2F2F2F;
    mix-blend-mode: normal;
    width: 100% !important;
    margin-bottom: 64px !important;
    margin-top: 15px !important;
  }

.control_service_content_de {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
  width: 100% !important;
  margin-top: 35px !important;
  margin-bottom: 32px !important;
}

.service_benfits_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  height: 88%;
}

.service_benfits_content {
  min-width: 57%;
  max-width: 850px;
  /* height: 255px; */
  margin-left: 5px;
  /* display: flex;
  justify-content: space-between; */
  text-align: left;
}

.service_3d_head {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #2F2F2F;
  margin-top: 24px;
}

.service_contact_us {
  display: none;
}

.project_contact_us {
  display: none;
}

.project_nums {
  margin-top: 15px;
}

.service_bottom_contacthide {
  display: none;
}

.service_bottom_contact_us {
  display: block;
  background: #0087FF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
  width: 100%;
  padding: 0px 16px;
}

.service_bottom_contact_us:hover, .service_bottom_contact_us:active {
  display: block;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #2F2F2F;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #2F2F2F;
  width: 100%;
  padding: 0px 16px;
}

.service_stepper_container {
  text-align: left;
  margin-top: 100px;
  padding: 0px;
}

.accordian_container {
  display: block;
  padding: 0px;
}

.accordion {
  padding: 0;
}

.accordion-item {
  margin-top: 16px;
  outline: none;
  border: none;
  box-shadow: none;
  border-radius: 10px !important;
}

.accordion-header {
  outline: none;
  box-shadow: none;
  border-radius: 10px !important;
}

.accordian-item {
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 10px !important;
}

.accordion-button, .accordion-button:focus {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 10px !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #2F2F2F;
  outline: none;
  border: none;
  padding: 10px 12px;
  min-height: 75px;
}

  .accordion-button:active, .accordion-button:not(.collapsed) {
  background-color: #FFFFFF;
  color: #2F2F2F;
  border: none;
  box-shadow: inset 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 10px;
  outline: none;
  padding: 24px 13px;
} 

.card-body {
  display: flex;
  flex-direction: column-reverse;
  padding-top: 0px;
}

.card-body p {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2F2F2F;
}

/* Custom style */
.solution_container .accordion-button::after {
  background-image: url("/src/assest/Icons2/plus.png");
  transform: scale(1.1) !important;
  padding-left: 15px;
}
.solution_container .accordion-button:not(.collapsed)::after {
  background-image: url("/src/assest/Icons2/download.png");
  transform: scale(1.1) !important;
  padding-left: 15px;
}

.mann_solution_container .accordion-button::after {
  background-image: url("/src/assest/Icons2/plus.png");
  transform: scale(1.1) !important;
  padding-left: 15px;
}
.mann_solution_container .accordion-button:not(.collapsed)::after {
  background-image: url("/src/assest/Icons2/download.png");
  transform: scale(1.1) !important;
  padding-left: 15px;
}

.service_stepper_container .accordion-button::after {
  background-image: url("/src/assest/Icons2/plus.png");
  transform: scale(1.1) !important;
  padding-left: 15px;
}
.service_stepper_container .accordion-button:not(.collapsed)::after {
  background-image: url("/src/assest/Icons2/download.png");
  transform: scale(1.1) !important;
  padding-left: 15px;
}

.contact_form_meeting_lang_mobile {
  align-items: left;
  margin-bottom: 24px;
  margin-top: 10px;
}



.position_first_content_block {
  width: 100%;
  margin-top: 145px;
  max-width: 100%;
  text-align: left;
  padding: 0px 10px;
}

.position_blueHead {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #0087FF;
}

.position_mainHead {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.2px;
  color: #2F2F2F;
}

.position_subblueHead {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #4EA0FD;
}

.positionFirst_content_subhead {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #2F2F2F;
}

.positionFirst_content_subcontent {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
  color: #2F2F2F;
}

.positionFirst_content_middle_subcontent {
  display: flex;
  flex-direction: column;
  margin: 0px 37px;
}


.position_second_content_block {
  margin-top: 40px;
  padding: 0px 10px;
}

.position_about_leaftech_head {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #2F2F2F;
}

.position_about_leaftech_content {
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
}

.position_third_content_subcontent {
  margin-top: 40px;
  padding: 0px 10px;
}

.responsibility_and_expect {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.resEexpect_content_block {
  display: flex;
  padding: 0px;
  width: 100%;
}


.sideline_ul {
  height: 97%;
  margin-top: 12px;
  display: none;
}


.p1_sideline_ul1 {
  display: none;
  height: 410px;
  margin-top: 15px;
  width: 4px;
}

.p1_sideline_ul2 {
  display: none;
  height: 410px;
  margin-top: 15px;
  width: 7px;
}

.p1_sideline_ul3 {
  display: none;
  height: 340px;
  margin-top: 15px;
  width: 4px;
}

.p1_sideline_ul4 {
  display: none;
  height: 350px;
  margin-top: 15px;
  width: 7px;
}

.p2_sideline_ul1 {
  display: none;
  height: 325px;
  margin-top: 15px;
  width: 4px;
}

.p2_sideline_ul2 {
  display: none;
  height: 288px;
  margin-top: 15px;
  width: 5px;
}

.p2_sideline_ul3 {
  display: none;
  height: 350px;
  margin-top: 15px;
  width: 4px;
}

.p2_sideline_ul4 {
  display: none;
  height: 350px;
  margin-top: 15px;
  width: 5px;
}



.position_resExpect_li {
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2F2F2F;
}

.position_resExpect_li::before {content: "•"; color: #0087FF;
  display: inline-block; width: 1em;
  margin-left: -1em
}

.position_fourth_content_block {
  margin-top: 40px;
  padding: 0px 10px;
}

.position_fifth_content_block {
  margin-top: 40px;
  padding: 0px 10px;
}

.weOffer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}


.weOffer_content_block {
  display: flex;
  padding: 0px;
}


.position_weOffer_li {
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2F2F2F;
}

.position_weOffer_li::before {content: "•"; color: #0087FF;
  display: inline-block; width: 1em;
  margin-left: -1em
}


.weOffer_sideline {
  height: 190px;
  width: 2px;
  display: none;
}

.position_sixth_content_block {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0px 10px;
  display: flex;
}

.moreInfo_container {
  display: flex;
  flex-direction: column;
}

.position_moreInfo {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
  margin-bottom: 40px;
}

.position_moreInfo_img_container {
  width: 40%;
}

.position_moreInfo_img {
 width: 140px;
}


.privacy_landing_toprow {
  background: none;
  margin-bottom: 0px;
  padding: 0px;
  margin-top: 60px;
  min-height: initial;
}

.privacy_head {
  /* style={{textAlign:'left', marginTop:'72px', width:'100%', padding: '0px 20px'}} */
  text-align: left;
  margin-top: 72px;
  width: 100%;
  padding: 0px;
}

.privacy_subhead {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.0025em;
  color: #2F2F2F;
  margin-top: 120px;
  text-align: left;
  padding: 0px;
}

.privacy_subContent {
  /* width: 66%; */
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  color: #2F2F2F !important;
  margin-top: 30px !important;
  text-align: left !important;
  padding: 0px !important;
}

.privacySubContent_row {
  text-align: left;
  padding: 0px;
  width: 100% !important;
  margin-left: 0px !important;
}

.privacySubContent_lastrow {
  text-align: left;
  padding: 0px 15px;
  width: 75% !important;
  margin-left: 15px !important;
  margin-bottom: 120px;
}

.privacy_links {
  margin-top: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #0087FF;
  padding: 0px;
}

.privacy_links:hover {
  margin-top: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #0087FF;
}

.privacy_smallSubhead {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: #2F2F2F;
  margin-top: 32px;
  padding: 0px;
}

.privacy_cookies_table_mobile {
 display: none;
}

.privacy_cookies_table_desktop {
  display: block;
  margin-top: 24px;
  padding: 0px;
}

.privacy_cookies_thead {
  background: #EEEEEE;
  border-radius: 20px;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.privacy_cookies_thead span {
  padding: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #2F2F2F;
}

.privacy_cookies_tbody span {
  padding: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #2F2F2F;
  margin-top: 20px;
}

.privacy_ul {
  border-top-right-radius: none;
  /* border-top-left-radius: none; */
  /* border-bottom-left-radius: none; */
  border-bottom-right-radius: none;
  border-left: none;
  /* width: 10px; */
  height: 96%;
  margin-top: 2%;
}

.privacy_li {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2F2F2F;
  margin-top: 15px;
}

.privacy_li::before {content: "•"; color: #0087FF;
  display: inline-block; width: 1em;
  margin-left: -1em
}

}

@media only screen and (min-width: 1500px) { 

  .sub_move_towards_body {
    width: 100%;
    text-align: -webkit-center;
  }

  .home_first_content {
    width: 896px;
    margin-left: 15px;
  }

  .home_first_image img { 
    /* margin-left: 183px !important; */
    /* width: 939px !important; */
  }
/* 
  .home_first_content_head {
    width: 74%;
  } */

 
}

@media only screen and (min-width: 1530px) { 
  .project_home {
    /* width: 1420px !important; */
    margin-left: 15px !important;
  }

  .project_outline  {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .result_image {
    margin-left: 20px !important;
    width: 45%;
    display: flex;
    justify-content: center;
  }

  .result_content {
    margin-left: 20px !important;
    width: 50%;
  }

  .service_3d_head {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-transform: capitalize;
    color: #2F2F2F;
  }

  /* .service_contact_us {
    display: none;
  }

  .project_contact_us {
    display: none;
  } */


}


@media only screen and (min-width: 1537px) { 
  .mann_project_desc {
    width: 50% !important;
    margin-left: 45px !important; 
  }


  .mann_project_desc_image {
      width: 45% !important;
  }

  .single_project_desc {
    width: 50% !important;
    margin-left: 45px !important; 
  }


  /* .single_project_desc_image {
      width: 700px !important;
  } */

  /* .home_first_content_head {
    width: 75%;
  } */
}

@media only screen and (min-width: 1850px) { 
  /* .projectImage {
    width: 40% !important;
  } */

  /* .for_bld_owners_content_box2 {
    margin-left: 75px !important;
  }
  .for_bld_owners_content_box5 {
    margin-left: 75px !important;
  } */
}

@media only screen and (min-width: 2050px) { 
  /* .projectImage {
    width: 35% !important;
  } */

  .for_bld_owners_content_box2 {
    margin-left: 105px !important;
  }
  .for_bld_owners_content_box5 {
    margin-left: 105px !important;
  }
}

@media only screen and (min-width: 2250px) { 
  /* .projectImage {
    width: 25% !important;
    margin-left: -125px;
  } */
}


/* responsive relese 1 quick fixes */

@media (min-width: 1181px) and (max-width: 1300px){
  .home__count .home_first_content_head, .home_first_content_head_de{
    font-size: 48px !important;
  }
  .home__count .home_first_subcontent{
    width: 500px !important;
    height: 160px !important;
  }
  .home__count .home_first_subcontent1{
    width: 500px !important;
    height: 120px !important;
  }
  .sub_move_towards_body{
    height: 100%;
  }
  .sub_move_towards_body .sub_move_towards_content{
    width: auto;
    margin-left: 0;
  }
  .project__wrapper .project-leading-relaxed{
    width: 475px !important;
  }
  .project-leading-header{
    width: 540px !important;
  }

  .sub_move_towards_body {
    padding: 0px 15px;
  }

  .career_position_container {
    padding: 18px
  }

  .career_position_content {
    font-size: 14px;
  }
}

/* @media (min-width: 1024px) and (max-width: 1250px){
  .home__count .home_first_content_head, .home_first_content_head_de{
    font-size: 44px !important;
  }
  .count__numbers--served{
    margin: 0px 20px !important;
  }
  .home_first_image img{
    width: 450px !important;
    top: 32% !important;
    left: 55%;
  }
  .single_project_desc_image, .mann_project_desc_image{
    width: 300px !important;
  }
  .phoenix .Clientbenefits{
    width: 400px;
  }
  .phoenix .results_benefits_subhead{
    width: 400px !important;
  }
  .container .clientImageBox{
    width: 165px;
  }
  .home__leaf .access_your_head{
    height: auto;
  }

  .contact__leaf .contact__leaf__info{
    width: 400px !important;
  }

  .contact__leaf .contact__leaf__info .contact_head{
    width: 400px;
  }

  .contact__leaf .contact__leaf__info .contact_subhead_buttons_text{
    font-size: 12px;
    width: 140px;
    display: inline-block;
    word-break: break-word;
    height: auto;
  }
  .contact__leaf .contact__leaf__info .contact_content{
    font-size: 16px;
  }

  .contact_form_Iagree_div{
    width: 350px !important;
  }

  .contact__leaf .contact__leaf__form .contact_form_Iagree{
    width: 300px;
    z-index: 2;
  }
  .contact__leaf .contact__leaf__form .captcha{
    z-index: 2;
  }
  .contact__leaf .contact__leaf-wrapper{
    display: flex;
    justify-content: space-evenly;
  }

} */