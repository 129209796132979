input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background: #FFFFFF;
  border-radius: 2px;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 26px;
  height: 26px;
  border: none;
  /* border-radius: 0.15em; */
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 13px;
  height: 13px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  /* box-shadow: inset 1em 1em var(--form-control-color); */
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
  border-radius: 0px;
  /* outline: max(2px, 0.15em) solid currentColor; */
  /* outline-offset: max(2px, 0.15em); */
}

input[type="checkbox"]:checked {
  transform: scale(1);
  border-radius: 0px;
  outline: max(1px, 0.15em) solid currentColor;
  /* outline-offset: max(2px, 0.15em); */
}

input[type="checkbox"]:focus {
  outline: max(1px, 0.1em) solid currentColor;
  /* outline-offset: max(2px, 0.15em); */
  border-radius: 0px !important;
}

.contact_form_Iagree_div input[type="checkbox"]{
  width: 26px;
  height: 26px;
}

.rc-anchor-alert {
  display: none !important;
}

#rc-anchor-alert, .rc-anchor-alert {
  display: none !important;
}
/* input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
} */


.contact-desk {
  display: block;
}

.contact-mob {
  display: none;
}

.contact_head {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 0.002em;
  color: #2F2F2F;
  margin-bottom: 24px;
  width: 575px;
}

.contact_content {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #2F2F2F;
  margin-bottom: 40px;
}

.contact_subhead {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #2F2F2F;
  margin-bottom: 16px;
  margin-top: 24px;
}

.contact_subhead_buttons_text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
  height: 43px;
  text-transform: uppercase;
  background: #FFFFFF;
  border: 1px solid #2F2F2F;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 2px;
  color: #2F2F2F;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact_subhead_buttons_text:hover {
  background: #0087FF;
  color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border: none;
}

.main {
  background: #EFF2F5;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
  height: 43px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 2px;
  color: #2F2F2F;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact_form_meeting_font {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: left;
}

.contact_form_lang_font {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 0px;
  margin-left: 8px;
}

.contact_form_checkbox {
  width: 26px;
  height: 26px;
  border: none;
  outline: none;
  border: 1px solid gray;
}

.contact_form_longInput {
  width: 480px;
  height: 47px;
  font-size: 14px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: none;
  outline: none;
  border: none !important;
}

.contact_form_shortInput {
  width: 233px;
  height: 47px;
  font-size: 14px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: none;
  outline: none;
  border: none !important;
}

.contact_form_longInput_error {
  width: 480px;
  height: 47px;
  font-size: 14px;
  background: #FFFFFF;
  border: 1px solid #EA0069 !important;
  border-radius: 10px;
}

.contact_form_shortInput_error {
  width: 233px;
  height: 47px;
  font-size: 14px;
  background: #FFFFFF;
  border: 1px solid #EA0069 !important;
  border-radius: 10px;
}

.contact_form_phoneInput {
  width: 335px;
  height: 47px;
  font-size: 14px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: none;
  outline: none;
  border: none !important;
}

.contact_form_shortnote {
  width: 480px;
  height: 134px;
  padding: 14px !important;
  font-size: 14px;
  background: #FFFFFF;
  border-radius: 10px;
}

.contact_form_shortnote:focus {
  border: 1px solid #2F2F2F !important;
}

input:focus, textarea:focus {
  border: 1px solid #2F2F2F !important;
  border-radius: 10px !important;
  text-decoration: none;
  box-shadow: none;
  outline: none;
}


.outline-none {
  outline: none !important;
  /* outline-offset: 2px; */
}

.contact_form_Iagree_div {
  width: 490px;
}


.contact_form_Iagree {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #2F2F2F;
  width: 501px;
}

.contact_form_Iagree_link {
  text-decoration: underline !important;
}

.disabled_contact_form_submitButton {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #B4B4B4;
  backdrop-filter: blur(30px);
  border-radius: 20px;
  padding: 0px 24px;
  gap: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #B4B4B4;
  margin-top: 48px;
}

.contact_form_submitButton {
  height: 54px;
  background: #0087FF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  gap: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
  margin-top: 48px;
}

.building_img {
  position: absolute;
  left: 36%;
  top: 630px;
  height: 416px;
  width: 328px;
  z-index: 0;
}

.usersit_img {
  position: absolute;
  /* left: 3%; */
  top: 802px;
  height: 291px;
  width: 266px;
}

.building_img_de {
  position: absolute;
  left: 36%;
  top: 815px;
  height: 416px;
  width: 328px;
  z-index: 0;

}

.usersit_img_de {
  position: absolute;
  left: 3%;
  top: 965px;
  height: 291px;
  width: 266px;
}

#name_error_text, #job_error_text, #email_error_text, #intrested_error_text, #represent_error_text, #mob_intrested_error_text, #mob_represent_error_text { 
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #EA0069;
}

#tab_name_error_text, #tab_job_error_text, #tab_email_error_text, #tab_intrested_error_text, #tab_represent_error_text{ 
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #EA0069;
}

.modal-content {
  width: 560px;
  background: #FFFFFF;
  box-shadow: 6px 9px 22px rgba(0, 135, 255, 0.08);
  border-radius: 10px;
}

.modal-dialog {
  top: 25%;
  bottom: 50%;
}

.modal_body{
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_content_2 {
  width: 508px;
  margin-left: -110px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal_main_head {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  letter-spacing: 0.0025em;
  color: #2F2F2F;
  /* margin-top: 63px; */
}

.modal_p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #2F2F2F;
  margin-top: 8px;

}

.show {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(30px);
}

.modalclosebtn {
  position: absolute;
  top: 12px;
  right: 20px;
  color: #B4B4B4;
  cursor: pointer;
  font-weight: 600;
}

.contact_desktop_view {
  display: block;
}

.contact_mobile_view {
  display: none;
}

.contact_tab_view {
  display: none;
}

.contact_page_top_container {
  background: linear-gradient(89.82deg, #FFFFFF 0.23%, rgba(255, 255, 255, 0.555775) 45.36%, rgba(228, 233, 239, 0.5) 83.29%, #E4E9EF 97.33%);
}


@media (max-width: 768px) {

  .contact_form_Iagree_div input[type="checkbox"]{
    width: 42px;
    height: 26px;
  }

  .contact-desk {
    display: none;
  }
  .contact-mob {
    display: block;
  }

  .contact_head {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.2px;
    color: #2F2F2F;
    width: 100% !important;
  }

  .contact_content {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
  }

}


@media (min-width: 200px) and (max-width: 550px) {

  .contact_page_top_container {
    background: none;
  }

  .contact_desktop_view {
    display: none;
  }

  .contact_mobile_view {
    display: block;
    margin-top: 30px;
    padding: 0px;
  }

  .contact_tab_view {
    display: none;
  }

  .contact_form_longInput {
    width: 100%;
    background: #FAFBFC;
    padding: 10px;
    border-radius: 10px;
  }

  .contact_form_shortInput {
    width: 100%;
    background: #FAFBFC;
    padding: 10px;
    border-radius: 10px;
  }

  .contact_form_shortnote {
    width: 100%;
    background: #FAFBFC;
    padding: 10px;
    border-radius: 10px;
  }

  .contact_form_phoneInput {
    width: 100%;
    background: #FAFBFC;
    padding: 10px;
    border-radius: 10px;
  }

  .contact_form_longInput_error {
    width: 100%;
    padding: 10px;
  }

  .contact_form_shortInput_error {
    width: 100%;
    padding: 10px;
  }


  .contact_form_Iagree_div {
    width: 100%;
  }

  .contact_subhead {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #2F2F2F;
    margin-bottom: 4px;
    margin-top: 24px;
  }
  
  .contact_subhead_buttons_text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    height: 30px;
    text-transform: uppercase;
    background: #FFFFFF;
    border: 1px solid #2F2F2F;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
  }

  .contact_subhead_buttons_text:hover {
    background: #FFFFFF;
    border: 1px solid #2F2F2F;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    color: #2F2F2F;
  }
  
  .contact_form_lang_font {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    margin-bottom: 0px;
    margin-left: 8px;
  }
  
  
  .contact_form_Iagree {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #2F2F2F;
    /* width: 501px; */
  }

  .contact_form_Iagree_link {
    text-decoration: underline !important;
  }

  .main {
    background: #E4E9EF;
    border-radius: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    padding: 8px;
    gap: 10px;
    height: 30px;
  }

  .contact_form_shortnote {
    height: 80px;
  }

  .building_img {
    position:initial; 
    left: 0%;
    top: 0px;
    height: 100% !important;
    width: 26% !important;
    z-index: 0;
  }

  .usersit_img {
    position:initial; 
    left: 0%;
    top: 0px;
    height: 100% !important;
    width: 30% !important;
    z-index: 0;
    margin-top: 30px;
  }

  .contact_form_submitButton  {
    margin-top: 30px;
    margin-left: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 1.5px;
    color: #FFFFFF;
    padding: 0px 16px;
    height: 42px;
  }

  
.modal-content {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 6px 9px 22px rgba(0, 135, 255, 0.08);
  border-radius: 10px;
}

.modal-dialog {
  top: 25%;
  bottom: 50%;
}

.modal_body{
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_content_2 {
  width: 100%;
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tower {
  display: none;
}

.modal_main_head {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #2F2F2F;
}

.modal_p {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #2F2F2F;
}

.show {
  background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(30px);
}

.modalclosebtn {
  position: absolute;
  top: 5px;
  right: 15px;
  color: #B4B4B4;
  cursor: pointer;
  font-weight: 600;
}

}


@media (min-width: 555px) and (max-width: 1180px) {

  .contact_page_top_container {
    background: none;
  }

  .contact_desktop_view {
    display: none;
  }

  .contact_mobile_view {
    display: none;
  }

  .contact_tab_view {
    display: block;
    margin-top: 30px;
  }

  .contact__leaf__info {
    margin-top: 94px;
    text-align: left;
  }

  .contact__leaf__form {
    background: linear-gradient(89.82deg, rgba(248, 251, 255, 0.527494) 0.23%, rgba(228, 233, 239, 0.5) 37.09%, #E4E9EF 97.33%);
    border-radius: 10px;
    width: 100%;
    padding: 60px 78px;
    margin-bottom: 64px;
    margin-top: 94px;
  }

  .contact_form_longInput {
    width: 100%;
    background: #FAFBFC;
    padding: 10px;
    border-radius: 10px;
  }

  .contact_form_shortInput {
    width: 100%;
    background: #FAFBFC;
    padding: 10px;
    border-radius: 10px;
  }

  .contact_form_shortnote {
    width: 100%;
    background: #FAFBFC;
    padding: 10px;
    border-radius: 10px;
  }

  .contact_form_phoneInput {
    width: 100%;
    background: #FAFBFC;
    padding: 10px;
    border-radius: 10px;
  }

  .contact_form_longInput_error {
    width: 100%;
    padding: 10px;
  }

  .contact_form_shortInput_error {
    width: 100%;
    padding: 10px;
  }


  .contact_form_Iagree_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .captcha {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact_subhead {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #2F2F2F;
    margin-bottom: 4px;
    margin-top: 24px;
  }
  
  .contact_subhead_buttons_text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    height: 30px;
    text-transform: uppercase;
    background: #FFFFFF;
    border: 1px solid #2F2F2F;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
  }

  .contact_subhead_buttons_text:hover {
    background: #FFFFFF;
    border: 1px solid #2F2F2F;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    color: #2F2F2F;
  } 
  
  .contact_form_lang_font {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #2F2F2F;
    margin-bottom: 0px;
    margin-left: 8px;
  }
  
  
  .contact_form_Iagree {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #2F2F2F;
  }

  .contact_form_Iagree_link {
    text-decoration: underline !important;
  }

  .main {
    background: #E4E9EF;
    border-radius: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #2F2F2F;
    padding: 8px;
    gap: 10px;
    height: 30px;
  }

  .contact_form_shortnote {
    height: 80px;
  }

  .building_img {
    position: absolute;
    left: 55%;
    top: 26%;
    height: 351px !important;
    width: 270px !important;
    z-index: -1;
  }

  .building_img_de {
    position: absolute;
    left: 55%;
    top: 26%;
    height: 351px !important;
    width: 270px !important;
    z-index: -1;
  }

  .usersit_img {
    position: absolute;
    left: 5%;
    top: 630px;
    height: 188px !important;
    width: 169px !important;
    z-index: 0;
    margin-top: 30px;
  }

  .usersit_img_de {
    position: absolute;
    left: 5%;
    top: 630px;
    height: 188px !important;
    width: 169px !important;
    z-index: 0;
    margin-top: 30px;
  }

  .contact_form_submitButton  {
    margin-top: 30px;
    margin-left: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 1.5px;
    color: #FFFFFF;
    padding: 0px 16px;
    height: 42px;
  }

  
.modal-content {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 6px 9px 22px rgba(0, 135, 255, 0.08);
  border-radius: 10px;
}

.modal-dialog {
  top: 25%;
  bottom: 50%;
}

.modal_body{
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_content_2 {
  width: 100%;
  margin-right: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tower {
  display: block;
}

.modal_main_head {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #2F2F2F;
}

.modal_p {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #2F2F2F;
}

.show {
  background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(30px);
}

.modalclosebtn {
  position: absolute;
    top: 12px;
    right: 20px;
  color: #B4B4B4;
  cursor: pointer;
  font-weight: 600;
}

.contact_form_Iagree_div input[type="checkbox"]{
  width: 26px;
  height: 26px;
}

.contact_head {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: #2F2F2F;
  margin-bottom: 24px;
  width: 575px;
}

.contact_content {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #2F2F2F;
  mix-blend-mode: normal;
  margin-bottom: 40px;
}

.contact_subhead {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #000000;
  margin-bottom: 16px;
  margin-top: 24px;
}

.contact_subhead_buttons_text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
  height: 43px;
  background: #FFFFFF;
  border: 1px solid #2F2F2F;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #2F2F2F;
}



}

@media (min-width: 1181px) and (max-width: 1300px){ 
  .building_img {
    left: 32%;
  }
}

input[type=checkbox] {
  border: 1px solid gray;
}