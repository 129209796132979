.arun_container {
  width: 100%;
  /* max-width: 100%; */
  /* media query use below */
  padding: 0px 15px 0px 15px; 
  margin: 0;
  display: flex;
  position: fixed;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.5);
  /* background-color: red; */
  backdrop-filter: blur(30px);
}

.container-flex-display{
  display: flex;
  position: relative;
}

.arun_logo {
  /* font-size: 2rem; */
  margin: auto 0px;
  color: #0087ff;
  margin-left: 20px;
  text-transform: capitalize;
}

.navFont {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  /* display: flex;
  align-items: center; */
  letter-spacing: 0.0025em;
  color: #2F2F2F;
  padding: 10px;
}




.arun_menuList .arunUL {
  display: flex;
  gap: 3.8rem;
  align-items: center;
  margin-left: inherit;
  font-weight: 500;
}


.arun_container .arun_menuList .arunUL .dd_link{
  /* font-family: 'Nunito Sans', sans-serif; */
  /* font-style: normal; */
  /* font-weight: 300 !important; */
  color: #2F2F2F;
  font-size: 16px;
  padding: 10px 6px;
  text-decoration: none;
  display: block;
  width: 85px;
  margin-left: 7px;
  margin-top: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
}

.leaftech_logo {
 width: 104px;
 height: 18px;
 margin-left: -5px;
}


.dd_link:hover {
  background-color: #F4F6F9;
  color: #2F2F2F;
  padding: 10px;
  text-decoration: none;
  display: block;
  margin-left: 7px;
  margin-top: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-weight: 700 !important;
}

.active {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 10px;
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #2F2F2F;
}

 .lang_options{
  margin-left: 10px;
 }

.lang_options ul {
  display: flex;
  align-items: center;
}

.lang_options .active{
  background: none;
  font-weight: 800;
}

.lang_options button {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  letter-spacing: 1.5px;
  color: #2F2F2F;
}

.lang_label {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 38px;
  letter-spacing: 1.5px;
  color: #2F2F2F;
  margin-left: 10px;
}

#langContent {
  font-size: 16px !important;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #2F2F2F;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration: none;
  /* margin-top: 16px; */
  font-style: normal !important;
  background: none !important;
}


/* to override the existing css for "a" tag */
#sub-header .active a {
  color: #FFFFFF;
  
}

.arun_container .arun_menuList {
  display: block;
  margin: 0.5rem 4rem;
 
}

.Contact_btn {
  background-color: #0087FF;
  color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  /* border: 1px solid rgb(29, 29, 29); */
  padding: 0px 24px;
  width: 106px;
  /* position:absolute;
  right: 2rem; */
  text-align: center;
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #FFFFFF;
}

.Contact_btn:hover {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #2F2F2F;
  box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
  color: #2F2F2F;
  /* flex: none; */
  /* order: 0;
  flex-grow: 0; */
}


.contactandLanguage {
  position: absolute;
  right: 1rem;
  margin: auto;
  gap: 1rem;
}

.arun_container .mobile_menubutton {
  display: none;
}
.arun_container .arun_mobilemenuList {
  display: none;
  position: relative;
}

/* Style The Dropdown Button */
.dropbtn {
  /* background-color: #4CAF50; */
  /* color: white; */
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}
#dropbtnimg{
  display: block;
}

.dropbtn:hover > #dropbtnimg{
  transform: rotate(180deg);
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* opacity: 1.9; */
  text-align: left;
  border-radius: 20px;
  font-family: "NunitoSans-Regular"
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #2F2F2F;
  font-size: 16px;
  padding: 10px 5px;
  text-decoration: none;
  display: block;
  margin-left: 7px;
  margin-top: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #F4F6F9;
  color: #2F2F2F;
  padding: 10px 5px;
  text-decoration: none;
  display: block;
  width: 145px;
  margin-left: 7px;
  margin-top: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-weight: 800;
}

.dropdown-content a:active {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 10px;
  font-weight: 800 !important;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #2F2F2F;
}

.desktop_logo {
  display: block;
}

.mobile_logo {
  display: none;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
/* .dropdown:hover .dropbtn {
  background-color: #3e8e41;
} */

@media (max-width: 1068px) {
  /* .arun_container {
    width: 100%;
    padding: 0.7rem;
    margin: 0;
    display: flex;
    background: rgba(255, 255, 255, 0.5);
  } */

  
  .arun_container .mobile_menubutton {
    display: block;
    /* width: inherit; */
    text-align: end;
    margin-right: 10px;
    margin-top: 0px;
  }

  .arun_container .arun_menuList ul {
    display: none;
  }

  .arun_container .arun_mobilemenuList {
    display: block;
    /* min-height: 360px; */
    max-height: 795px;
    min-height: 636px;
    width: 100%;
    text-align: left;
    /* margin: 20% 0%; */
    /* justify-content: center; */
    line-height: 50px;
    /* margin-left: 15px; */
  }

  .dropbtn {
    padding: 0%;
  }
}

.header_container {
  display: flex;
  position: relative;
  /* flex-direction: row; */
  width: 100%;
  /* margin-left: 0px; */
}

@media (min-width: 1440px) { 
  .arun_logo {
    margin-left: 20px;
  }
}


@media (min-width: 200px) and (max-width: 550px){ 

  .arun_container {
    width: 100%;
    /* height: 100%; */
    /* top: 15px; */
    background: none;
    padding: 20px 15px 0px 0px; 
  }

  .arun_logo {
   margin: 0px;
  }

  .leaftech_logo {
    /* width: 180px; */
    height: 18px;
    margin-left: 20px;
  }

  .arunUL {
    margin-left: 10px;
    margin-top: 25px;
  }

  .arunUL li{
    margin-top: 16px;
  }

  .desktop_logo {
    display: block;
    margin-top: 0px;
  }

  .mobile_logo {
    display: block;
    margin-left: 0px !important;
  }
  

  .header_container {
    display: flex;
    width: 100%;
    align-items:flex-start;
    justify-content: center;
    position:unset;
    flex-direction: column;
    margin-left: 0px;
    overflow: scroll;
  }

  .contactandLanguage {
    left: 0px;
  }

  .contactandLanguage .arunUL { 
    margin-top: 0px;
  }

  .arun_mobilemenuList {
    height: 555px;
    overflow: scroll;
    padding-bottom: 40px;
  }

  .arun_container .arun_menuList {
    display: block;
    margin: 0.5rem 5rem;
  }


  .nav_mobile_contact {
    margin-top: 32px;
  }


  .arun_container .accordion-button:active, .accordion-button:not(.collapsed), .accordion-button:focus, .accordion-button, .show {
    background: none !important;
    /* backdrop-filter: blur(30px) !important; */
  } 

  .arun_container .accordian_container button {
    padding: 0px 0px 0px 15px !important;
    height: 52px !important;
    margin-top: 15px !important;
    margin-left: 0px !important;
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    letter-spacing: 1.5px;
    color: #2F2F2F;
    background: none;
    /* backdrop-filter: blur(30px); */
    border: none;
    box-shadow: none;
  }

  .accordion-header {
    /* background: rgba(255, 255, 255, 0.5) !important;
    backdrop-filter: blur(30px) !important; */
  }

  .arun_container .accordian_container button p {
    margin-left: 0px;
  }

   .arun_container .accordion-button::after {
    background-image: url('/src/assest/Icons/chevron.png') !important;
    transform: rotate(180deg);
    margin-left: 20px;
    margin-top: -4px;
    background-size: 12px;
  }
  .arun_container .accordion-button:not(.collapsed)::after {
    background-image: url('/src/assest/Icons/chevron-up-solid.svg');
    transform: rotate(0deg);
    margin-left: 25px;
    margin-top: 11px;
    background-size: 12px;
  }

  
  .arun_container .accordian_container {
    margin-left: -13px;
    margin-top: 10px;
    /* background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(30px); */
  }

  .arun_container .accordion-item {
    /* background: rgba(255, 255, 255, 0.5) !important; */
    /* backdrop-filter: blur(30px); */
   width: 55%;
   margin-left: 8px;
  }

  .arun_container .card-body {
    margin-top: 0px;
    background: none;
    /* backdrop-filter: blur(30px); */
  }

  .arun_container .card-body a{
    padding: 10px;
  }

  .dd_link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    letter-spacing: 1.5px;
    color: #2F2F2F;
    padding: 10px;
  }

  .Contact_btn, .Contact_btn:hover {
    background-color: #0087FF;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    /* border: 1px solid rgb(29, 29, 29); */
    padding: 0px 24px;
    width: 106px;
    /* position:absolute;
    right: 2rem; */
    text-align: center;
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 1.5px;
    color: #FFFFFF;
  }
  
}

@media (min-width: 550px) and (max-width: 1180px){ 
  .active {
    width: 125px;
  }

  .arun_container {
    width: 100%;
    /* height: 100%; */
    /* top: 15px; */
    background: none;
    padding: 20px 15px 0px 0px; 
  }

  .arun_container .mobile_menubutton {
    display: block;
    /* width: inherit; */
    text-align: end;
    margin-right: 10px;
    margin-top: 0px;
  }

  .arun_container .arun_menuList ul {
    display: none;
  }

  .arun_container .arun_mobilemenuList {
    display: block;
    /* min-height: 360px; */
    max-height: 795px;
    min-height: 636px;
    width: 100%;
    text-align: left;
    /* margin: 20% 0%; */
    /* justify-content: center; */
    line-height: 50px;
    /* margin-left: 15px; */
  }

  .dropbtn {
    padding: 0%;
  }

  .arun_logo {
   margin: 0px;
  }

  .leaftech_logo {
    /* width: 180px; */
    height: 18px;
    margin-left: 20px;
  }

  .arunUL {
    margin-left: 10px;
    margin-top: 25px;
  }

  .arunUL li{
    margin-top: 16px;
  }

  .desktop_logo {
    display: block;
    margin-top: 0px;
  }

  .mobile_logo {
    display: block;
    margin-left: 0px !important;
  }
  

  .header_container {
    display: flex;
    width: 100%;
    align-items:flex-start;
    justify-content: center;
    position:unset;
    flex-direction: column;
    margin-left: 0px;
    /* overflow: scroll; */
  }

  .contactandLanguage {
    left: 0px;
  }

  .contactandLanguage .arunUL { 
    margin-top: 0px;
  }

  .arun_mobilemenuList {
    height: 780px;
  }

  .arun_container .arun_menuList {
    display: block;
    margin: 0.5rem 5rem;
  }


  .nav_mobile_contact {
    margin-top: 32px;
  }


  .arun_container .accordion-button:active, .accordion-button:not(.collapsed), .accordion-button:focus, .accordion-button, .show {
    background: none !important;
    /* backdrop-filter: blur(30px) !important; */
  } 

  .arun_container .accordian_container button {
    padding: 0px 0px 0px 15px !important;
    height: 52px !important;
    margin-top: 15px !important;
    margin-left: 0px !important;
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    letter-spacing: 1.5px;
    color: #2F2F2F;
    background: none;
    /* backdrop-filter: blur(30px); */
    border: none;
    box-shadow: none;
  }

  .accordion-header {
    /* background: rgba(255, 255, 255, 0.5) !important;
    backdrop-filter: blur(30px) !important; */
  }

  .arun_container .accordian_container button p {
    margin-left: 0px;
  }

   .arun_container .accordion-button::after {
    background-image: url('/src/assest/Icons/chevron.png') !important;
    transform: rotate(180deg);
    margin-left: 20px;
    margin-top: -4px;
    background-size: 12px;
  }
  .arun_container .accordion-button:not(.collapsed)::after {
    background-image: url('/src/assest/Icons/chevron-up-solid.svg');
    transform: rotate(0deg);
    margin-left: 25px;
    margin-top: 11px;
    background-size: 12px;
  }

  
  .arun_container .accordian_container {
    margin-left: -13px;
    margin-top: 10px;
    /* background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(30px); */
  }

  .arun_container .accordion-item {
    /* background: rgba(255, 255, 255, 0.5) !important; */
    /* backdrop-filter: blur(30px); */
   width: 18%;
   margin-left: 8px;
  }

  .arun_container .card-body {
    margin-top: 0px;
    background: none;
    /* backdrop-filter: blur(30px); */
  }

  .arun_container .card-body a{
    padding: 10px;
  }

  .dd_link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    letter-spacing: 1.5px;
    color: #2F2F2F;
    padding: 10px;
  }

  .Contact_btn, .Contact_btn:hover {
    background-color: #0087FF;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 135, 255, 0.25);
    /* border: 1px solid rgb(29, 29, 29); */
    padding: 0px 24px;
    width: 106px;
    /* position:absolute;
    right: 2rem; */
    text-align: center;
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 1.5px;
    color: #FFFFFF;
  }

  
}


