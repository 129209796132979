.service3_d {
    display:block;
}

.service3_t{
    display: none;
}
@media (max-width:768px){
    .service3_d {
        display:none;
    }
    .service3_t{
        display: block;
    }
}